import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { AuthContext } from "../context";
import { IdleTimeOutModal } from "./idleTimeOutModal";

const IdleTimeout = props => {
  const idleTimer = useRef();
  const { state } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const timeoutRef = useRef(isTimedOut);
  let wtRef = useRef();
  let history = useHistory();

  function onAction(e) {
    setIsTimedOut(false);
  }

  function onActive(e) {
    setIsTimedOut(false);
  }

  function handleClose() {
    if (wtRef.current) clearTimeout(wtRef.current);
    idleTimer.current.reset();
    timeoutRef.current = false;
    setIsTimedOut(false);
    setShowModal(false);
  }

  function handleLogout() {
    if (wtRef.current) clearTimeout(wtRef.current);
    setShowModal(false);
    history.push("/logout");
  }

  function onIdle(e) {
    if (isTimedOut) {
      history.push("/logout");
    } else {
      setShowModal(true);
      wtRef.current = setTimeout(handleLogout, props.warnTimeout);
      idleTimer.current.reset();
      setIsTimedOut(true);
    }
  }

  return (
    <>
      {state.token && (
        <>
          <IdleTimer
            ref={idleTimer}
            element={document}
            onAction={onAction}
            onActive={onActive}
            onIdle={onIdle}
            debounce={250}
            timeout={props.timeout}
          />
          <IdleTimeOutModal
            showModal={showModal}
            handleClose={handleClose}
            handleLogout={handleLogout}
          />
        </>
      )}
    </>
  );
};

export default IdleTimeout;
