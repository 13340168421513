import React, { Children, useContext } from "react";
import { AuthContext } from "../context/authContext";

const YaleNav = props => {
  const { state } = useContext(AuthContext);
  //const childLinks = Children.toArray(props.children).forEach(child => (
  const childLinks = Children.map(props.children, child => (
    <>
      {Children.toArray(child).length > 0 && (
        <div className={"main-nav-link-wrapper align-self-center"}>{child}</div>
      )}
    </>
  ));

  return (
    <>
      {state.token ? (
        <div className={"ml-auto align-self-center"}>{childLinks}</div>
      ) : (
        <></>
      )}
    </>
  );
};

export default YaleNav;
