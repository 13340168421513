import { useState } from "react";

export const SORT_ASC = "asc";
export const SORT_DESC = "desc";

const useSortableTable = rows => {
  const [currSortDir, setCurrSortDir] = useState(SORT_ASC);
  const [currSortCol, setCurrSortCol] = useState("");

  const sortTable = column_name => {
    let sortDir = currSortDir;
    if (currSortCol !== column_name) {
      //  Always start new columns ascending
      sortDir = SORT_ASC;
    } else if (currSortDir === "desc") {
      sortDir = SORT_ASC;
    } else {
      sortDir = SORT_DESC;
    }
    setCurrSortCol(column_name);
    setCurrSortDir(sortDir);

    rows.sort(comparer(column_name, sortDir));
  };

  const comparer = (key, order) => {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const A = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const B = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (A === B) return 0;
      else if (A === null) comparison = 1;
      // Nulls sort first ascending
      else if (B === null) comparison = -1;
      else if (A > B) comparison = 1;
      else if (A < B) comparison = -1;

      return order === SORT_DESC ? comparison * -1 : comparison;
    };
  };

  return [sortTable, currSortCol, currSortDir];
};

export default useSortableTable;
