import React from "react";
import { BreadcrumbTitle, PageHeader } from "../layout";

const Registrar = props => {
  return (
    <div>
      <PageHeader
        breadcrumbs={
          <BreadcrumbTitle pageTitle={"Course List"}></BreadcrumbTitle>
        }
        navLinks={<></>}
        pageTitle={"Course List"}
      />
    </div>
  );
};

export default Registrar;
