import React from "react";
import { BreadcrumbTitle, PageHeader } from "../layout";
import { Link, useParams } from "react-router-dom";
import Error from "../components/error";
import SortableColumnHeader from "../components/sortableColumnHeader";
import useSortableTable from "../hooks/useSortableTable";
import Moment from "react-moment";
import useCredit from "../hooks/useCredit";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const CreditReport = props => {
  const { termcode } = useParams();
  //const [creditList, setCreditList] = useState([]);

  const [
    creditList,
    loadingCreditList,
    loadingCredit,
    showCredits,
    savingCredits,
    setCredits,
    credits,
    student,
    LawCredit
  ] = useCredit({ props, termcode });

  const [sortTable, currSortCol, currSortDir] = useSortableTable(creditList);

  return (
    <div>
      <PageHeader
        breadcrumbs={
          <BreadcrumbTitle pageTitle={"Variable Credit Report"}>
            <Link to="/courses">Course List</Link>
          </BreadcrumbTitle>
        }
        pageTitle={"Variable Credit Report"}
      />

      <Error />

      <div className={"container"}>
        <div className={"row justify-content-left"}>
          {loadingCreditList ? (
            <div className={"row"}>
              <div className={"col"}>
                <b>Loading Credits...</b>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col">
                {creditList.length > 0 ? (
                  <table className="table yltable">
                    <caption>
                      <b className={"sr-only"}>Course Selection Table</b>
                      <br />
                      Select column headers to sort data in ascending order.
                      Select again for descending order.
                    </caption>
                    <thead className={"smallerfont"}>
                      <tr>
                        <SortableColumnHeader
                          columnName={"regCourse"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Registred Course
                        </SortableColumnHeader>
                        <th>Edit</th>
                        <SortableColumnHeader
                          columnName={"lastName"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Student Name
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"bannerId"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          SID
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"additionalCreditCode"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Paper Requirement
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"varCredits"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Variable Credits
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"creditHours"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Current Credits
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"creditDate"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Save Date
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"processed"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Completed
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"processedNetid"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Completed By
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"processedDate"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Completed Date
                        </SortableColumnHeader>
                      </tr>
                    </thead>
                    <tbody className={"smallerfont"}>
                      {creditList.map((val, index) => {
                        return (
                          <tr key={val.lastName + index}>
                            <td>{val.regCourse}</td>
                            <td>{LawCredit?.showEditButton(index)}</td>
                            <td>
                              {val.lastName}
                              {val.lastName !== null && ","} {val.firstName}
                            </td>
                            <td>{val.bannerId}</td>
                            <td>{val.additionalCreditCode}</td>
                            <td>{val.varCredits}</td>
                            <td>{val.creditHours}</td>
                            <td>
                              {val.creditDate && (
                                <div>
                                  <Moment format="MM/DD/YYYY">
                                    {val.creditDate}
                                  </Moment>
                                  <br />
                                  <Moment format="hh:mm:ss A">
                                    {val.creditDate}
                                  </Moment>
                                </div>
                              )}
                            </td>
                            <td>{val.processed}</td>
                            <td>{val.processedNetid}</td>
                            <td>
                              {val.processedDate && (
                                <div>
                                  <Moment format="MM/DD/YYYY">
                                    {val.processedDate}
                                  </Moment>
                                  <br />
                                  <Moment format="hh:mm:ss A">
                                    {val.processedDate}
                                  </Moment>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div>No credit reports</div>
                )}
              </div>
            </div>
          )}
        </div>
        <Modal
          show={showCredits}
          onHide={() => LawCredit.closeLawCredit()}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ marginTop: "0px" }}>
              Grade Comments
              {student !== null && (
                <span>
                  {" "}
                  for {student?.firstName} {student?.lastName}
                </span>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"row"}>
              <div className={"col"}>
                <strong>Term: {termcode}</strong>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <strong>{student?.crseTitle}</strong>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <strong>{student?.subjCrseList}</strong>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <strong>CRN: {student?.crnList}</strong>
              </div>
            </div>
            <div className={"row my-2"}>
              <div className={"col"}>
                <hr />
              </div>
            </div>
            <div className={"row my-2"}>
              {loadingCredit ? (
                <div>
                  <b>Loading Credits...</b>
                </div>
              ) : (
                <div className={"col"}>
                  In this course, the student has fulfilled the requirements for
                  <div className={"form-check"}>
                    <label className={"form-check-label"}>
                      <input
                        name={"additionalCreditCode"}
                        checked={credits?.additionalCreditCode === "LSUB"}
                        className={"form-check-input"}
                        type={"radio"}
                        value={"LSUB"}
                        onChange={e => {
                          setCredits({
                            ...credits,
                            additionalCreditCode: e.target.value
                          });
                        }}
                      />
                      Substantial Paper Credit
                    </label>
                  </div>
                  <div className={"form-check"}>
                    <label className={"form-check-label"}>
                      <input
                        name={"additionalCreditCode"}
                        checked={credits?.additionalCreditCode === "LSAW"}
                        className={"form-check-input"}
                        type={"radio"}
                        value={"LSAW"}
                        onChange={e => {
                          setCredits({
                            ...credits,
                            additionalCreditCode: e.target.value
                          });
                        }}
                      />
                      Supervised Analytical Writing
                    </label>
                  </div>
                  <div className={"form-check"}>
                    <label className={"form-check-label"}>
                      <input
                        name={"additionalCreditCode"}
                        checked={credits?.additionalCreditCode === "NONE"}
                        className={"form-check-input"}
                        type={"radio"}
                        value={"NONE"}
                        onChange={e => {
                          setCredits({
                            ...credits,
                            additionalCreditCode: e.target.value
                          });
                        }}
                      />
                      N/A
                    </label>
                  </div>
                  <div className={"row my-2"}>
                    <div className={"col"}>
                      <hr />
                    </div>
                  </div>
                  <div className={"row my-2"}>
                    <div className={"col"}>
                      Number of credits the student should be awarded:
                      <input
                        type={"text"}
                        className="form-control"
                        id="varCredits"
                        maxLength={3}
                        value={
                          credits?.varCredits == null ? "" : credits?.varCredits
                        }
                        onChange={e => {
                          setCredits({
                            ...credits,
                            varCredits: e.target.value
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={"row my-2"}>
                    <div className={"col"}>
                      <hr />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"col"}>
                      <div className={"form-check"}>
                        <label
                          className={"form-check-label"}
                          htmlFor={"creditComplete"}
                        >
                          <input
                            className={"form-check-input"}
                            id={"creditComplete"}
                            type={"checkbox"}
                            checked={credits.processed === "Y"}
                            onChange={e => {
                              LawCredit.updateProcessed(e);
                            }}
                          />
                          Processed
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {!loadingCredit && (
              <Button
                variant="secondary"
                onClick={() => LawCredit.closeLawCredit()}
              >
                Close
              </Button>
            )}
            {loadingCredit ? (
              <Button disabled variant="primary">
                Saving Please Wait...
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled={!LawCredit.canSubmitCredits()}
                onClick={() => {
                  LawCredit.saveLawCredit(credits);
                }}
              >
                Submit
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CreditReport;
