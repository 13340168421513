import React, { useState, useEffect, useContext, useRef } from "react";
import { BreadcrumbTitle, PageHeader } from "../layout/index";
import { NavLink, useParams, Redirect } from "react-router-dom";
import useAPI from "../hooks/useAPI";
import { AuthContext } from "../context/authContext";
import useSortableTable from "../hooks/useSortableTable";
import SortableColumnHeader from "../components/sortableColumnHeader";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useEOT from "../hooks/useEOT";

const EotIseRcd = props => {
  const [http] = useAPI(props);
  const { state, dispatch, inRole } = useContext(AuthContext);
  const [ploading, setPloading] = useState(true);
  const [eots, setEots] = useState([]);
  const [read, setRead] = useState("N");
  const [sortTable, currSortCol, currSortDir] = useSortableTable(eots);
  let { rpttype, term, crn, crseid, pidm } = useParams();
  const [termcode, setTermCode] = useState(state.selected_term);
  const [termList, setTermList] = useState([]);
  let courseid = null;
  const [
    course,
    loading,
    loadingEOT,
    loadingISE,
    showEOT,
    savingEOT,
    showISE,
    savingISE,
    EOT,
    student,
    initialReport,
    report,
    setReport
  ] = useEOT({
    props,
    termcode,
    courseid
  });

  const insEmailEOT = useRef(null);
  const insEmailISE = useRef(null);

  useEffect(() => {
    if (state.selected_term) {
      http
        .post("/api/EndOfTerm/RCDList", {
          termCode: state.selected_term,
          read: read
        })
        .then(res => {
          setEots(res.data.reports);
        })
        .then(() => {
          setPloading(false);
          if (rpttype && term && crn && crseid && pidm) {
            ShowViaRoute();
          }
        });
    } else {
      // No default term, use first term
      if (termList?.length > 0) {
        TermChange(termList[0].termCode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termList]);

  useEffect(() => {
    http.get("/api/EndOfTerm/getRcdTermList").then(res => {
      setTermList(res.data.terms);
    });
  }, []);

  function TermChange(val) {
    setEots([]);
    setTermCode(val);
    setPloading(true);
    http
      .post(`/api/EndOfTerm/RCDList`, {
        termCode: val,
        read: read
      })
      .then(function(res) {
        setEots(res.data.reports);
      })
      .then(function() {
        dispatch({ type: "TERM_CHANGE", payload: val });
        sessionStorage.setItem(
          "token",
          JSON.stringify({ ...state, selected_term: val })
        );
        setPloading(false);
      });
  }

  function StatusChange(val) {
    setEots([]);
    setPloading(true);
    http
      .post(`/api/EndOfTerm/RCDList`, {
        termCode: state.selected_term,
        read: val
      })
      .then(function(res) {
        setEots(res.data.reports);
      })
      .then(function() {
        setRead(val);
        setPloading(false);
      });
  }

  function ShowViaRoute() {
    setPloading(true);
    setTermCode(term);
    http
      .post(`/api/EndOfTerm/RCDList`, {
        termCode: term,
        read: "N"
      })
      .then(function(res) {
        setEots(res.data.reports);
      })
      .then(function() {
        dispatch({ type: "TERM_CHANGE", payload: term });
        sessionStorage.setItem(
          "token",
          JSON.stringify({ ...state, selected_term: term })
        );
        setPloading(false);
        if (rpttype === "EOT") {
          EOT.openEOTRCD(term, pidm, crn, crseid);
        } else {
          EOT.openISERCD(term, pidm, crn, crseid);
        }
      });
  }

  function openReport(val) {
    if (val.reportType === "End of Term Report") {
      EOT.openEOTRCD(val.termCode, val.studentPidm, val.crn, val.courseId);
    } else {
      EOT.openISERCD(val.termCode, val.studentPidm, val.crn, val.courseId);
    }
    let rptindex = eots.findIndex(
      x =>
        x.reportType === val.reportType &&
        x.termCode === val.termCode &&
        x.studentPidm === val.studentPidm &&
        x.crn === val.crn
    );
    let rpt = eots[rptindex];
    if (rpt.read === "N") {
      rpt.read = "Y";
      let arr = [...eots];
      arr.splice(rptindex, 1);
      setEots(arr);
    }
  }

  return inRole("ROLE_RCD") || inRole("ROLE_RCAA") ? (
    <div>
      <PageHeader
        breadcrumbs={
          <BreadcrumbTitle pageTitle={"End of Term Reports"}>
            <NavLink to="/courses">Course List</NavLink>
          </BreadcrumbTitle>
        }
        pageTitle={"End of Term Reports"}
      />
      <div className={"container"}>
        <div className={"row justify-content-left"}>
          <div className={"col-md-4"}>
            <div className="form-group">
              <label htmlFor="Term" className={"form-control-md mr-2"}>
                Term
              </label>
              <select
                id={"Term"}
                className="form-control-md"
                style={{ minWidth: "200px" }}
                value={state.selected_term}
                onChange={e => {
                  TermChange(e.target.value);
                }}
              >
                {termList.map((val, index) => {
                  return (
                    <option key={index} value={val.termCode}>
                      {val.stvtermDesc}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className={"col-md-4"}>
            <div className="form-group">
              <label htmlFor="Read" className={"form-control-md mr-2"}>
                Status
              </label>
              <select
                id={"Read"}
                className="form-control-md"
                style={{ minWidth: "200px" }}
                value={read}
                onChange={e => {
                  StatusChange(e.target.value);
                }}
              >
                <option value="N">Unread</option>
                <option value="Y">Read</option>
                })}
              </select>
            </div>
          </div>
        </div>
        {ploading ? (
          <div className={"row"}>
            <div className={"col"}>
              <b>Loading Reports...</b>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col">
              {eots.length === 0 ? (
                <div className={"alert alert-info"}>
                  No {read === "Y" ? "read " : "unread "} reports found for this
                  term
                </div>
              ) : (
                <table className="table yltable table-hover">
                  <caption>
                    <b className={"sr-only"}>Reports Table</b>
                    <br />
                    Select column headers to sort data in ascending order.
                    Select again for descending order.
                  </caption>
                  <thead className={"smallerfont"}>
                    <tr>
                      <th>View Report </th>
                      <SortableColumnHeader
                        columnName={"termCode"}
                        sortTableValue={sortTable}
                        currentSortDirection={currSortDir}
                        currentSortColumn={currSortCol}
                      >
                        Term
                      </SortableColumnHeader>
                      <SortableColumnHeader
                        columnName={"crseTitle"}
                        sortTableValue={sortTable}
                        currentSortDirection={currSortDir}
                        currentSortColumn={currSortCol}
                      >
                        Title
                      </SortableColumnHeader>
                      <SortableColumnHeader
                        columnName={"lastName"}
                        sortTableValue={sortTable}
                        currentSortDirection={currSortDir}
                        currentSortColumn={currSortCol}
                      >
                        Student
                      </SortableColumnHeader>
                      <SortableColumnHeader
                        columnName={"reportType"}
                        sortTableValue={sortTable}
                        currentSortDirection={currSortDir}
                        currentSortColumn={currSortCol}
                      >
                        Report Type
                      </SortableColumnHeader>
                      <SortableColumnHeader
                        columnName={"savedDate"}
                        sortTableValue={sortTable}
                        currentSortDirection={currSortDir}
                        currentSortColumn={currSortCol}
                      >
                        Saved Date
                      </SortableColumnHeader>
                      <SortableColumnHeader
                        columnName={"read"}
                        sortTableValue={sortTable}
                        currentSortDirection={currSortDir}
                        currentSortColumn={currSortCol}
                      >
                        Status
                      </SortableColumnHeader>
                    </tr>
                  </thead>
                  <tbody className={"smallerfont"}>
                    {eots.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <button
                              title={"View " + val.reportType}
                              aria-label={"View " + val.reportType}
                              className={"btn btn-sm btn-default"}
                              onClick={() => {
                                openReport(val);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faFileAlt}
                                style={{ fontSize: "1.5em" }}
                              />
                            </button>
                          </td>
                          <td>{val.termCode}</td>
                          <td>{val.crseTitle}</td>
                          <td>
                            {val.lastName}, {val.firstName}
                          </td>
                          <td>{val.reportType}</td>
                          <td>
                            <Moment format="MM/DD/YYYY">{val.savedDate}</Moment>
                          </td>
                          <td>{val.read === "N" ? "Unread" : "Read"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        <Modal
          size="lg"
          show={showEOT}
          onHide={() => {
            setReport(initialReport);
            EOT.closeEOT();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ marginTop: "0px" }}>
              Yale College End of Term Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className={"row"}>
                <div className={"col mb-2"}>
                  <div>
                    <span className={"mr-3"}>
                      Term: {state.selected_term}
                      <span className={"mr-3"}></span>
                      <br />
                      {course.CourseInfo.crseTitle}
                    </span>
                    <br />
                    <span className={"mr-3"}>
                      {course.CourseInfo.subjCrseList}
                    </span>
                    <br />
                    {student && (
                      <div>
                        <div>{`${student.crn}`}</div>
                      </div>
                    )}
                    <div>
                      <span>{course.CourseInfo.primaryInstructorName}</span>
                      {student && (
                        <div>
                          <div>
                            {`${student.collOrResdDesc}, ${student.classYear}`}
                          </div>
                          <div>
                            <b>{`${student.lastName}, ${student.firstName}`}</b>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {loadingEOT ? (
                <div>
                  <b>Loading Report...</b>
                </div>
              ) : (
                <div>
                  <div>
                    <b>Reason</b>
                  </div>
                  <div className={"form-check"}>
                    <label className={"form-check-label"}>
                      <input
                        name="reason"
                        checked={report.reason === "F"}
                        className={"form-check-input mr-2"}
                        type="radio"
                        disabled={true}
                        value="F"
                      />
                      The student has <b>failed</b> the course. In what way was
                      the student's work unsatisfactory? Please make comments
                      below.
                    </label>
                  </div>
                  <div className={"form-check"}>
                    <label className={"form-check-label"}>
                      <input
                        name="reason"
                        checked={report.reason === "E"}
                        className={"form-check-input mr-2"}
                        type="radio"
                        value="E"
                        disabled={true}
                      />
                      The student has done <b>excellent work</b> in the course.
                      Please make comments below that would help the Residential
                      College Dean in writing letters of recommendation for the
                      student.
                    </label>
                  </div>
                  <div className={"form-check"}>
                    <label className={"form-check-label"}>
                      <input
                        name="reason"
                        checked={report.reason === "O"}
                        className={"form-check-input mr-2"}
                        type="radio"
                        value="O"
                        disabled={true}
                      />
                      Other. The student has{" "}
                      <b>neither failed the course nor done excellent work</b>,
                      but the work nonetheless calls for special comment.
                    </label>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="comments">Comments</label>
                    <textarea
                      className="form-control"
                      style={{ fontWeight: "normal" }}
                      id="comments"
                      rows="3"
                      maxLength="4000"
                      value={report.comments}
                      disabled={true}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      Submitted by instructor/ On behalf of instructor
                    </label>
                    <input
                      title={"Instructor Email"}
                      id="instructor_email"
                      type="text"
                      ref={insEmailEOT}
                      className="form-control"
                      value={report.email == null ? "" : report.email}
                      disabled={true}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Instructor Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      value={report.phone}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                EOT.closeEOT();
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                EOT.printEOT();
              }}
            >
              Print
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={showISE}
          onHide={() => {
            setReport(initialReport);
            EOT.closeISE();
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ marginTop: "0px" }}>
              Yale College Independent Study Evaluation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className={"row"}>
                <div className={"col mb-2"}>
                  <div>
                    <span className={"mr-3"}>
                      Term: {state.selected_term}
                      <span className={"mr-3"}></span>
                      <br />
                      {course.CourseInfo.crseTitle}
                    </span>
                    <br />
                    <span className={"mr-3"}>
                      {course.CourseInfo.subjCrseList}
                    </span>
                    <br />
                    {student && (
                      <div>
                        <div>{`${student.crn}`}</div>
                      </div>
                    )}
                    <div>
                      <span>{course.CourseInfo.primaryInstructorName}</span>
                      {student && (
                        <div>
                          <div>
                            {`${student.collOrResdDesc}, ${student.classYear}`}
                          </div>
                          <div>
                            <b>{`${student.lastName}, ${student.firstName}`}</b>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {loadingISE ? (
                <div>
                  <b>Loading Report...</b>
                </div>
              ) : (
                <div>
                  <div className="form-group mt-3">
                    <label htmlFor="comments">Comments</label>
                    <textarea
                      className="form-control"
                      style={{ fontWeight: "normal" }}
                      id="comments"
                      rows="3"
                      maxLength="4000"
                      value={report.comments}
                      disabled={true}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      Submitted by instructor/ On behalf of instructor
                    </label>
                    <input
                      title={"Instructor Email"}
                      id="instructor_email"
                      type="text"
                      ref={insEmailISE}
                      className="form-control"
                      value={report.email == null ? "" : report.email}
                      disabled={true}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Instructor Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      value={report.phone}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <>
              <Button
                variant="primary"
                onClick={() => {
                  EOT.closeISE();
                }}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  EOT.printISE();
                }}
              >
                Print
              </Button>
            </>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  ) : (
    <Redirect to="/unauthorized" />
  );
};

export default EotIseRcd;
