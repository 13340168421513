import { Col, Row } from "react-bootstrap";
import React from "react";

export const DeptReportTable = ({reportData}) => {
    const emptyCourses = [];

    const view =
        reportData.map(({courseInfo, studentInfo}) => {
            if (studentInfo.length === 0) {
                emptyCourses.push(courseInfo)
                return null;
            }
            return (<>
                <hr/>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <Row>
                                <Col>
                                    <strong>{courseInfo.crseTitle}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>{courseInfo.subjCrseList}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>{courseInfo.crnList}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>{courseInfo.primaryInstructorName}</strong>
                                </Col>
                            </Row>
                        </div>
                        <div className={"col"}>
                            <div className={"text-md-right"}>
                                <strong>Term: {courseInfo.termCode}</strong>
                            </div>
                        </div>
                    </div>
                    <div className={'pt-3'}>
                        <table
                            className={"table table-striped table-sm yltable"}
                            id={"rosterTable"}
                        >
                            <thead>
                            <tr>
                                <th scope={"col"} >
                                    <div>Student Name</div>
                                </th>
                                <th scope={"col"} >
                                    <div>Student ID</div>
                                </th>
                                <th scope={"col"} >
                                    <div>School/Res. Coll</div>
                                </th>
                                <th scope={"col"} >
                                    <div>Class</div>
                                </th>
                                <th scope={"col"}>
                                    <div>Major</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody className={"smallerfont"}>
                            {studentInfo.map((student, index) => {
                                return (<tr key={index}>
                                    <td > {student.fullName} </td>
                                    <td > {student.bannerId} </td>
                                    <td > {student.collOrResdCode} </td>
                                    <td > {student.classYear} </td>
                                    <td> {student.primaryMajrDesc} </td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>)
        })

    const emptyCoursesView = (<>
        <hr/>
        <div className={"container"}>
            <strong class="text-center">Courses that returned with no students in them</strong>
            <div className={'pt-3'}>
                <table
                    className={"table table-striped table-sm yltable"}
                    id={"emptyCoursesTable"}
                >
                    <thead>
                    <tr>
                        <th scope={"col"} >
                            <div>Course Subject List</div>
                        </th>
                        <th scope={"col"} >
                            <div>Course Title</div>
                        </th>
                        <th scope={"col"} >
                            <div>Primary Instructor</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody className={"smallerfont"}>
                    {emptyCourses.map((course, index) => {
                        return (<tr key={index}>
                            <td > {course.subjCrseList} </td>
                            <td > {course.crseTitle} </td>
                            <td > {course.primaryInstructorName} </td>
                        </tr>)
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    </>);

    return (<>
        {view}
        {emptyCourses.length > 0 && emptyCoursesView}
    </>);
}