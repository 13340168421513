import React, { useContext, useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import * as config from "../config";
import Modal from "react-bootstrap/Modal";
import useAPI from "../hooks/useAPI";
import oldFgs from "./old_fgs.png";
import { AuthContext } from "../context";
import parse from "html-react-parser";

const Home = props => {
  const [showInfo, setShowInfo] = useState(false);
  const [instructionsUrl, setInstructionsUrl] = useState(null);
  const [http] = useAPI(props);
  const { state } = useContext(AuthContext);

  const handleClose = () => setShowInfo(false);
  const handleShow = () => setShowInfo(true);

  useEffect(() => {
    //  Page title matches h1
    document.title = "Faculty Grade Submission";
    http
      .post("/api/user/appconfig", {
        configGroup: null,
        configCode: "INSTRUCTIONS_URL"
      })
      .then(res => {
        setInstructionsUrl(res.data.AppConfig.value);
      });
  }, []);

  return (
    <div>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-12 text-center"}>
            <h1 className={"py-5"}>Faculty Class Rosters - Grade Submission</h1>
            <br />
            <div style={{ textAlign: "center" }}>
              {!navigator.cookieEnabled ? (
                <div className={"alert alert-danger"}>
                  <h3 className={"alert-heading"}>Cookies not enabled</h3>
                  <p>Cookies must be enabled for this site to continue.</p>
                </div>
              ) : (
                <>
                  {state.error && (
                    <div className={"alert alert-danger"}>
                      {parse(state.error)}
                    </div>
                  )}
                  <div>
                    <p style={{ textAlign: "center" }}>
                      Please click on the button below to start this
                      application.
                    </p>
                    <Button
                      onClick={() =>
                        (window.location.href = `${config.API_URL}/oauth/authenticate/cas`)
                      }
                    >
                      Log into Grade Submission
                    </Button>
                  </div>
                </>
              )}
            </div>
            <p className={"mt-4"} style={{ textAlign: "center" }}>
              Unauthorized access will be prosecuted.
              <br />
              Use of Yale’s electronic systems is governed by applicable laws
              and policies.
              <br />
            </p>
            <p className={"mt-4"} style={{ textAlign: "center" }}>
              <a href={"#changeModal"} onClick={handleShow}>
                Why does grade submission look different?
              </a>
            </p>
            <Modal id={"changeModal"} show={showInfo} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Grade Submission Changes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className={"mt-4"}>Expecting to see this?</p>
                <Image src={oldFgs} fluid />
                <p className={"mt-4"}>
                  The previous Faculty Class Rosters/Grade Submission
                  application has been updated and refreshed. Please see the{" "}
                  <a target={"_blank"} href={instructionsUrl}>
                    instructions
                  </a>{" "}
                  to find out more about the new features of Faculty Class
                  Rosters/Grade Submission.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant={"primary"} onClick={handleClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
