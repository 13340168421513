import React from "react";

export default function BreadcrumbTitle({ pageTitle, children }) {
  return (
    <nav aria-label={"breadcrumb"}>
      <ol className={"breadcrumb"}>
        {children !== undefined &&
          React.Children.map(children, (child, i) => {
            return (
              <li key={"breadcrumb-" + i} className={"breadcrumb-item"}>
                {child}
              </li>
            );
          })}
        <li className={"breadcrumb-item"} aria-current={"page"}>
          {pageTitle}
        </li>
      </ol>
    </nav>
  );
}
