import React, { useState, useEffect, useContext } from "react";
import { BreadcrumbTitle, PageHeader, YaleNav } from "../layout/index";
import useAPI from "../hooks/useAPI";
import { AuthContext } from "../context/authContext";
import { NavLink } from "react-router-dom";
import useSortableTable from "../hooks/useSortableTable";
import SortableColumnHeader from "../components/sortableColumnHeader";
import {DeptReportView} from '../components/deptReportView';

const CourseSelection = props => {
  const { state, dispatch, inRole } = useContext(AuthContext);
  const [courses, setCourses] = useState([]);
  const [courseFilter, setCourseFilter] = useState("all");
  const changeFilter = event => {
    setCourseFilter(event.target.value);
  };
  const [http] = useAPI(props);
  const [sortTable, currSortCol, currSortDir] = useSortableTable(courses);
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    if (state.selected_term) {
      http.get(`/api/courses/list/${state.selected_term}`).then(res => {
        setCourses(res.data.Courses);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.BannerUser.terms]);

  useEffect(() => {
    if (state.selected_term) {
      setCourses([]);
      http.get(`/api/courses/list/${state.selected_term}`).then(res => {
        let courses = res.data.Courses;
        if (courseFilter === "pending") {
          courses = courses.filter(course => course.pendingGradesCount > 0);
        } else if (courseFilter === "approved") {
          courses = courses.filter(course => course.approvedGradesCount > 0);
        } else if (courseFilter === "returned") {
          courses = courses.filter(course => course.returnedGradesCount > 0);
        } else if (courseFilter === "submitted") {
          courses = courses.filter(
            course =>
              course.courseEnrlCount ===
              course.courseRolledCount + course.pendingGradesCount
          );
        } else if (courseFilter === "complete") {
          courses = courses.filter(
            course => course.courseEnrlCount === course.approvedGradesCount
          );
        }

        setCourses(courses);
      });
    }
  }, [courseFilter]);

  useEffect(() => {
    setOwners([...new Set(courses?.map(course => course.owner))]);
  }, [courses]);

  function isAllLaw() {
    return owners.length === 1 && owners[0] === "LW";
  }

  function hasLawCourse() {
    return owners.length >= 1 && owners.includes("LW");
  }

  function TermChange(val) {
    setCourses([]);
    http
      .get(`/api/courses/list/${val}`)
      .then(function(res) {
        setCourses(res.data.Courses);
      })
      .then(function() {
        dispatch({ type: "TERM_CHANGE", payload: val });
        sessionStorage.setItem(
          "token",
          JSON.stringify({ ...state, selected_term: val })
        );
      });
  }

  return (
    <div>
      <PageHeader
        breadcrumbs={
          <BreadcrumbTitle pageTitle={"Course List"}></BreadcrumbTitle>
        }
        navLinks={
          <>
            {(inRole("ROLE_DPRX") ||
              inRole("ROLE_RCD") ||
              inRole("ROLE_REGS")) && (
              <YaleNav>
                {inRole("ROLE_DPRX") && (
                  <DeptReportView key={`deptReportView-${state.selected_term}`} selectedTerm={state.selected_term}/>
                )}
                {inRole("ROLE_RCD") && (
                  <NavLink to={`/rcdeotise`}>End of Term Reports</NavLink>
                )}
                {inRole("ROLE_REGS") && state.selected_term && (
                  <NavLink to={`/creditreport/${state.selected_term}`}>
                    Variable Credit Reports
                  </NavLink>
                )}
              </YaleNav>
            )}
          </>
        }
        pageTitle={"Course List"}
      />
      <div className={"container"}>
        <div className={"row justify-content-left"}>
          <div className={"col-md-4"}>
            <div className="form-group">
              <label htmlFor="Term" className={"form-control-md mr-2"}>
                Courses for term
              </label>
              <select
                id={"Term"}
                className="form-control-md"
                style={{ minWidth: "200px" }}
                value={state.selected_term}
                onChange={e => {
                  TermChange(e.target.value);
                }}
              >
                {state.BannerUser.terms.map((val, index) => {
                  return (
                    <option key={index} value={val.termCode}>
                      {val.stvtermDesc}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {inRole("ROLE_REGS") && (
            <div className={"col-md-4"}>
              <div className="form-group">
                <label htmlFor="Term" className={"form-control-md mr-2"}>
                  Show courses
                </label>
                <select
                  id={"course-filter"}
                  className="form-control-md"
                  style={{ minWidth: "200px" }}
                  value={courseFilter}
                  onChange={e => {
                    changeFilter(e);
                  }}
                >
                  <option value={"all"}>All</option>
                  <option value={"pending"}>Pending approval</option>
                  <option value={"approved"}>Approved</option>
                  <option value={"returned"}>Returned to Instructor</option>
                  <option value={"submitted"}>
                    Instructor Grading Complete
                  </option>
                  <option value={"complete"}>Completed</option>
                </select>
              </div>
            </div>
          )}
        </div>
        {courses.length === 0 ? (
          <div className={"row"}>
            <div className={"col"}>
              <b>Loading Courses...</b>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col">
              <table className="table yltable">
                <caption>
                  <b className={"sr-only"}>Course Selection Table</b>
                  <b>Note:</b> Course rosters in Faculty Grade Submission are
                  updated in real-time.
                  <br />
                  Select column headers to sort data in ascending order. Select
                  again for descending order.
                  <br />
                </caption>
                <thead className={"smallerfont"}>
                  <tr>
                    <SortableColumnHeader
                      columnName={"subjCrseList"}
                      sortTableValue={sortTable}
                      currentSortDirection={currSortDir}
                      currentSortColumn={currSortCol}
                    >
                      Course
                    </SortableColumnHeader>
                    <SortableColumnHeader
                      columnName={"crseTitle"}
                      sortTableValue={sortTable}
                      currentSortDirection={currSortDir}
                      currentSortColumn={currSortCol}
                    >
                      Title
                    </SortableColumnHeader>
                    {inRole("ROLE_REGS") && (
                      <SortableColumnHeader
                        columnName={"primaryInstructorName"}
                        sortTableValue={sortTable}
                        currentSortDirection={currSortDir}
                        currentSortColumn={currSortCol}
                      >
                        Instructor
                      </SortableColumnHeader>
                    )}
                    <SortableColumnHeader
                      columnName={"courseEnrlCount"}
                      sortTableValue={sortTable}
                      currentSortDirection={currSortDir}
                      currentSortColumn={currSortCol}
                    >
                      Total Enrolled
                    </SortableColumnHeader>
                    {hasLawCourse() ? (
                      <>
                        <SortableColumnHeader
                          columnName={"pendingGradesCount"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Processing
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"returnedGradesCount"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Returned
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"approvedGradesCount"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Posted
                        </SortableColumnHeader>
                        {inRole("ROLE_REGS") && (
                          <SortableColumnHeader
                            columnName={"variableCreditCount"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            Var. Credit
                          </SortableColumnHeader>
                        )}
                      </>
                    ) : (
                      <SortableColumnHeader
                        columnName={"courseRolledCount"}
                        sortTableValue={sortTable}
                        currentSortDirection={currSortDir}
                        currentSortColumn={currSortCol}
                      >
                        Total Grades Submitted
                      </SortableColumnHeader>
                    )}
                    {!isAllLaw() && (
                      <>
                        <SortableColumnHeader
                          columnName={"withdrawnCount"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Total Withdrawn
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"excusedFromExamCount"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Total ABX
                        </SortableColumnHeader>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody className={"smallerfont"}>
                  {courses.map((val, index) => {
                    return (
                      <tr key={val.courseId}>
                        <td>
                          <NavLink
                            to={`/course/${state.selected_term}/${val.courseId}`}
                          >
                            {val.subjCrseList}
                          </NavLink>
                        </td>
                        <td>{val.crseTitle}</td>
                        {inRole("ROLE_REGS") && (
                          <td>{val.primaryInstructorName}</td>
                        )}
                        <td>{val.courseEnrlCount}</td>
                        {hasLawCourse() ? (
                          <>
                            <td>
                              {val.holdGrades === "Y"
                                ? val.pendingGradesCount
                                : "N/A"}
                            </td>
                            <td>
                              {val.holdGrades === "Y"
                                ? val.returnedGradesCount
                                : "N/A"}
                            </td>
                            <td>
                              {val.holdGrades === "Y"
                                ? val.approvedGradesCount
                                : val.courseRolledCount}
                            </td>
                            {inRole("ROLE_REGS") && (
                              <td>
                                {val.holdGrades === "Y"
                                  ? val.variableCreditCount
                                  : "N/A"}
                              </td>
                            )}
                          </>
                        ) : (
                          <td>{val.courseRolledCount}</td>
                        )}
                        {!isAllLaw() && (
                          <>
                            <td>{val.withdrawnCount}</td>
                            <td>{val.excusedFromExamCount}</td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseSelection;
