import React from "react";

const ShortCourseTitle = props => {
  const truncateTitle = title => {
    if (title === null) return title;
    let titleArray = title.split("/");
    if (titleArray.length <= 2) return title;

    return titleArray[0] + "/" + titleArray[1] + "/...";
  };

  return <>{truncateTitle(props.title)}</>;
};

export default ShortCourseTitle;
