/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import axios from "axios";
import * as config from "../config";
import { AuthContext } from "../context/authContext";

const useAPI = props => {
  const { state, dispatch, inRole } = useContext(AuthContext);
  const headers = () => {
    if (navigator.cookieEnabled) {
      let auth = sessionStorage.getItem("token");
      if (auth) {
        auth = JSON.parse(auth);
        return { Authorization: `Bearer ${auth.token}` };
      }
    }
  };

  axios.interceptors.request.use(
    function(config) {
      config.headers = headers();
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response && error.response.status === 401) {
        if (props.history) {
          props.history.push("/sessionended");
        }
      }
      return Promise.reject(error);
    }
  );

  const http = {
    get: url => {
      return axios.get(config.API_URL + url);
    },

    del: url => {
      return axios.delete(config.API_URL + url);
    },

    put: (url, data) => {
      return axios.put(config.API_URL + url, data);
    },

    post: (url, data) => {
      return axios.post(config.API_URL + url, data);
    }
  };

  return [http];
};

export default useAPI;
