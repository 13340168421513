/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../context/authContext";
import { Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import useAPI from "../hooks/useAPI";

export default function YaleHeader({ appTitle, props }) {
  const { state } = useContext(AuthContext);
  const [http] = useAPI(props);
  const [instructionsUrl, setInstructionsUrl] = useState(null);
  let location = useLocation();

  useEffect(() => {
    http
      .post("/api/user/appconfig", {
        configGroup: null,
        configCode: "INSTRUCTIONS_URL"
      })
      .then(
        res => {
          setInstructionsUrl(res.data.AppConfig.value);
        },
        error => {
          //  Handle any errors
          console.log(error.response.status + " instruction lookup");
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <header>
        <div className={"yale-topper"}>
          <Container>
            <Row>
              <div className={"col text-center"}>
                <a
                  className={
                    "sr-only sr-only-focusable text-white d-inline-block mt-2 p-1"
                  }
                  href={"#main"}
                >
                  Skip to main content
                </a>
              </div>
            </Row>
            <Row>
              <div className={"topper-logo"}>
                <div
                  className={"text"}
                  style={{ left: "0px", transformOrigin: "200.5px 12px 0px" }}
                >
                  <a href={"https://www.yale.edu"}>
                    <span
                      style={{
                        fontFamily:
                          "'YaleNew-Roman', 'YaleNew Roman', 'YaleNew'",
                        fontStyle: "normal",
                        color: "#FFFFFF"
                      }}
                    >
                      Yale University
                    </span>
                  </a>
                  <Link to="/">
                    <span
                      className={"ml-4"}
                      style={{
                        fontFamily:
                          "'YaleNew-Italic', 'YaleNew Italic', 'YaleNew Roman', 'YaleNew'",
                        fontStyle: "italic",
                        color: "#9FD9FD"
                      }}
                    >
                      {appTitle}
                    </span>
                  </Link>
                </div>
              </div>
              <div className={"ml-md-auto align-self-center"}>
                {location.pathname !== "/instructions" && (
                  <div
                    className={
                      "main-nav-link-wrapper ml-auto align-self-center"
                    }
                  >
                    <a target={"_blank"} className={""} href={instructionsUrl}>
                      Instructions
                    </a>
                  </div>
                )}
                {state.token ? (
                  <div
                    className={
                      "main-nav-link-wrapper ml-auto align-self-center"
                    }
                  >
                    <Link to={"/logout"}>
                      Logout ({state.BannerUser.netid}
                      {state.BannerUser.passthruNetid && (
                        <span className={"font-italic"}>
                          {" as "}
                          {state.BannerUser.passthruNetid}
                        </span>
                      )}
                      )
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Row>
          </Container>
        </div>
      </header>
    </div>
  );
}
