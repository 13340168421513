import React from "react";

const UnAuthorized = () => {
  return (
    <div>
      <div className={"container mt-5"}>
        <div className={"row"}>
          <div className={"col"}>
            <div className={"alert alert-warning"}>Unauthorized!</div>
            <div>
              <p>You are not authorized to access that resource.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnAuthorized;
