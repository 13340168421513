import React from "react";

export const InstructorList = ({ instructors }) => {
  return (
    <>
      {instructors !== undefined ? (
        <>
          {instructors.map((instructor, idx) => {
            return (
              <span key={"inst-" + idx}>
                {idx !== 0 && <>, </>}
                <strong key={"inst-" + idx}>
                  {instructor.firstname} {instructor.lastname}
                </strong>
                {instructor.role === "PINS" && <> (Primary)</>}
              </span>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default InstructorList;
