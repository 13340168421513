import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Error = props => {
  const [show, setShow] = useState(false);
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (state.error) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [state.error]);

  const handleClose = () => {
    dispatch({ type: "ERROR", payload: null });
    setShow(false);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Body>
          <div>
            <h3>Error</h3>
            <div className={"alert alert-warning"}>{state.error}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Error;
