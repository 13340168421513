import React from "react";
import Button from "react-bootstrap/Button";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const PDFTable = ({ course, termCode, orientation, anonymous }) => {
  const fileExtension = ".pdf";

  const columns = () => {
    if (anonymous === "Y") {
      return [
        { header: "Term", dataKey: "termCode" },
        { header: "Grd", dataKey: "finalGrade" },
        { header: "Exam ID", dataKey: "examId" },
        { header: "Due Date", dataKey: "submissionDeadlineDate" }
      ];
    } else {
      return [
        { header: "Term", dataKey: "termCode" },
        { header: "Grd", dataKey: "finalGrade" },
        { header: "Aud", dataKey: "audit" },
        { header: "Last", dataKey: "lastName" },
        { header: "First", dataKey: "firstName" },
        { header: "Email", dataKey: "emailAlias" },
        { header: "SID", dataKey: "sid" },
        { header: "NetId", dataKey: "netid" },
        course.courseInfo.anonymous === "Y"
          ? { header: "Exam ID", dataKey: "examId" }
          : { header: "YUPI", dataKey: "yupi" },
        { header: "Cls", dataKey: "classYear" },
        { header: "Major", dataKey: "primaryMajrDesc" },
        { header: "School", dataKey: "collOrResdDesc" },
        { header: "Course", dataKey: "regCourse" },
        { header: "Due Date", dataKey: "submissionDeadlineDate" }
      ];
    }
  };

  const columnStyles = () => {
    if (anonymous === "Y") {
      return {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 15 },
        3: { cellWidth: 30 }
      };
    } else {
      return {
        0: { cellWidth: 10 },
        1: { cellWidth: 7 },
        2: { cellWidth: 7 },
        3: { cellWidth: 22 },
        4: { cellWidth: 22 },
        5: { cellWidth: 42 },
        6: { cellWidth: 15 },
        7: { cellWidth: 13 },
        8: { cellWidth: 15 },
        9: { cellWidth: 7 },
        10: { cellWidth: 20 }
      };
    }
  };

  const arrayToPdf = (course, termCode, orientation) => {
    let fileName = `gradelist_${termCode}_${course.courseInfo.subjCrseList
      .replace(/ /g, "_")
      .replace(/\//g, "-")}`;
    let header = `${course.courseInfo.subjCrseList} - ${course.courseInfo.crseTitle} - ${termCode}`;

    const doc = new jsPDF({ format: "letter", orientation: orientation });
    doc.autoTable({
      body: course.gradeList,
      columns: columns(),
      margin: { top: 20 },
      columnStyles: columnStyles(),
      styles: { cellPadding: 0.5, fontSize: 8 },
      didDrawPage: function(data) {
        //  Course header
        doc.setFontSize(12);
        doc.setFontStyle("bold");
        doc.text(header, data.settings.margin.left, 12);
        doc.text(
          course.courseInfo.primaryInstructorName,
          data.settings.margin.left,
          18
        );

        //  Page number footer
        doc.setFontSize(10);
        doc.setFontStyle("normal");

        let pageNum = "Page " + doc.internal.getNumberOfPages();
        let pageSize = doc.internal.pageSize;
        let pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(pageNum, data.settings.margin.left, pageHeight - 10);
      }
    });
    doc.save(fileName + fileExtension);
  };

  return (
    <Button
      className={"btn btn-sm btn-yale p-1"}
      //        onClick={e => exportToPdf(tableId, fileName, header, orientation)}
      onClick={e => arrayToPdf(course, termCode, orientation)}
    >
      Export as PDF
    </Button>
  );
};
