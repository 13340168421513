import React from "react";
import Button from "react-bootstrap/Button";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const PdfDeptReport = ({reportData, parameters, loading}) => {
    const fileExtension = '.pdf';

    const columns = [
        {header: "Student Name", dataKey: "fullName"},
        {header: "Student ID", dataKey: "bannerId"},
        {header: "School/Res. Coll", dataKey: "collOrResdCode"},
        {header: "Class", dataKey: "classYear"},
        {header: "Major", dataKey: "primaryMajrDesc"},
    ];

    const columnStyles = {
        0: {cellWidth: 50},
        1: {cellWidth: 30},
        2: {cellWidth: 30},
        3: {cellWidth: 20},
        4: {cellWidth: 50},
    };

    const arrayToPdf = () => {
        let fileName = `dept_report_${parameters.termSelection}_${parameters.deptSelection}_${parameters.schoolSelection}_${parameters.gradeSelection}_${parameters.gradSelection}`;

        const emptyCourses = [];
        let firstPrinted = false;
        const doc = new jsPDF({ format: "letter", orientation: "p" });

        reportData.forEach(({courseInfo, studentInfo}) => {
            if (studentInfo.length === 0) {
                emptyCourses.push(courseInfo)
            }
            else {
                let header = `${courseInfo.subjCrseList} - ${courseInfo.crseTitle} - ${parameters.termSelection}`;
                const tableOptions = {
                    body: studentInfo,
                    columns: columns,
                    margin: { top: 20 },
                    rowPageBreak: 'avoid',
                    columnStyles: columnStyles,
                    styles: { cellPadding: 0.5, fontSize: 8 },
                    didDrawPage: function(data) {
                        //  Course header
                        doc.setFontSize(12);
                        doc.setFontStyle("bold");
                        doc.text(header, data.settings.margin.left, 12);
                        if (courseInfo.primaryInstructorName) {
                            doc.text(
                                courseInfo.primaryInstructorName,
                                data.settings.margin.left,
                                18
                            );
                        }
                        else {
                            doc.setFontStyle("italic");
                            doc.text(
                                'No Instructor Listed',
                                data.settings.margin.left,
                                18
                            );
                        }

                        //  Page number footer
                        doc.setFontSize(10);
                        doc.setFontStyle("normal");

                        let pageNum = "Page " + doc.internal.getNumberOfPages();
                        let pageSize = doc.internal.pageSize;
                        let pageHeight = pageSize.height
                            ? pageSize.height
                            : pageSize.getHeight();
                        doc.text(pageNum, data.settings.margin.left, pageHeight - 10);
                    }
                }
                // If pagebreak is 'always' at first table, first page is blank. This fixes that
                if (!firstPrinted) {
                    doc.autoTable({
                        ...tableOptions,
                        pageBreak: 'avoid'
                    })
                }
                else {
                    doc.autoTable({
                        ...tableOptions,
                        pageBreak: 'always'
                    })
                }

                firstPrinted = true;
            }
        });

        if (emptyCourses.length > 0) {
            let header = `Courses that returned with no students in them`
            const tableOptions = {
                body: emptyCourses,
                columns: [
                    {header: "Course Subject List", dataKey: "subjCrseList"},
                    {header: "Course Title", dataKey: "crseTitle"},
                    {header: "Primary Instructor", dataKey: "primaryInstructorName"},
                ],
                margin: { top: 15 },
                styles: { cellPadding: 0.5, fontSize: 8 },
                didDrawPage: function(data) {
                    //  Course header
                    doc.setFontSize(12);
                    doc.setFontStyle("bold");
                    doc.text(header, data.settings.margin.left, 12);

                    //  Page number footer
                    doc.setFontSize(10);
                    doc.setFontStyle("normal");

                    let pageNum = "Page " + doc.internal.getNumberOfPages();
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                        ? pageSize.height
                        : pageSize.getHeight();
                    doc.text(pageNum, data.settings.margin.left, pageHeight - 10);
                }
            }
            if (!firstPrinted) {
                doc.autoTable({
                    ...tableOptions,
                    pageBreak: 'avoid'
                })
            }
            else {
                doc.autoTable({
                    ...tableOptions,
                    pageBreak: 'always'
                })
            }
        }

        doc.save(fileName + fileExtension);
    };

    return (
        <Button variant={"primary"}
                disabled={loading}
                onClick={!loading ? arrayToPdf : null}>
            Export as PDF
        </Button>
    );
}