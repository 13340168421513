import React, { useEffect } from "react";
import "./yale-ui/css/yale-ui.css";
import "./App.css";
import { AuthContext } from "./context/index";
import Routes from "./Routes";
import * as Reducer from "./reducers/AppReducer";
import { GetToken } from "./services/authService";
import { LiveAnnouncer } from "react-aria-live";

function App(props) {
  const [state, dispatch] = React.useReducer(
    Reducer.reducer,
    Reducer.initialState
  );

  const inRole = role_name => {
    let r = state.user.roles.find(x => x === role_name);
    return !!r ? true : false;
  };

  useEffect(() => {
    dispatch({ type: "LOGIN", payload: GetToken() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch, inRole }}>
      <LiveAnnouncer>
        <Routes></Routes>
      </LiveAnnouncer>
    </AuthContext.Provider>
  );
}

export default App;
