import React from "react";
import Button from "react-bootstrap/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportCSV = ({ course, term, fileName, tabName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (course, term, fileName, tabName) => {
    tabName = tabName.replace(/[/*?[\]]/g, " "); // Remove illegal characters
    if (tabName.length > 31) tabName = tabName.substring(0, 27) + "...";
    const ws = XLSX.utils.json_to_sheet(course.gradeList, {
      dateNF: "MM/DD/YYYY"
    });
    const wb = { Sheets: { [tabName]: ws }, SheetNames: [tabName] };
    let infoSheet = XLSX.utils.aoa_to_sheet(courseTuples(course, term));
    XLSX.utils.book_append_sheet(wb, infoSheet, "Course Info");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      cellDates: true,
      type: "array"
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const courseTuples = (course, term) => {
    let tuples = [];

    tuples.push(["Term", term]);
    tuples.push(["Title", course.courseInfo.crseTitle]);
    tuples.push(["Subject/Course", course.courseInfo.subjCrseList]);
    tuples.push(["CRN(s)", course.courseInfo.crnList]);
    tuples.push([
      "Instructors",
      course.instructors
        .map(
          inst =>
            inst.firstname +
            " " +
            inst.lastname +
            (inst.role === "PINS" ? " (Primary)" : "")
        )
        .join()
    ]);
    tuples.push(["Total Enrolled", course.totals.totalEnrolled]);
    tuples.push(["Total Withdrawn", course.totals.withdrawnCount]);
    tuples.push(["Total Excused/ABX", course.totals.excusedFromExamCount]);
    tuples.push(["Total Saved", course.totals.totalGraded]);
    tuples.push(["Total Submitted", course.totals.totalSubmitted]);

    return tuples;
  };

  return (
    <Button
      className={"btn btn-sm btn-yale py-1"}
      onClick={e => exportToCSV(course, term, fileName, tabName)}
    >
      Export as Excel
    </Button>
  );
};

export default ExportCSV;
