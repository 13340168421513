import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { LiveMessage } from "react-aria-live";

const PageTitle = props => {
  useEffect(() => {
    //  Page title matches h1
    document.title = props.title;
  }, [props.title]);

  return (
    <Container>
      <LiveMessage
        message={"Loaded " + props.title + " page"}
        aria-live={"polite"}
      />
      <h1 tabIndex={"-1"}>{props.title}</h1>
    </Container>
  );
};

export default PageTitle;
