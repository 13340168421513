/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import swal from "sweetalert";

const useCredit = ({ props, termcode }) => {
  const [http] = useAPI(props);
  const [loadingCreditList, setLoadingCreditList] = useState(true);
  const [loadingCredit, setLoadingCredit] = useState(true);
  const [savingCredits, setSavingCredits] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [student, setStudent] = useState(null);
  const [stuIndex, setStuIndex] = useState(null);
  //const [creditList, cdispatch] = useReducer(CreditReducer, initialState);
  const [creditList, setCreditList] = useState([]);

  const initialCredit = {
    termCode: termcode,
    studentPidm: null,
    crn: null,
    additionalCreditCode: "NONE",
    varCredits: null,
    savedCommentText: "",
    processed: "N"
  };
  const [credits, setCredits] = useState(initialCredit);

  useEffect(() => {
    if (termcode) {
      http
        .get(`/api/LawCredit/reportList/${termcode}`)
        .then(res => {
          setCreditList(res.data.CreditList);
        })
        .then(() => {
          setLoadingCreditList(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termcode]);

  const LawCredit = {
    openLawCredit: idx => {
      setLoadingCredit(true);
      setStuIndex(idx);

      let stu = creditList[idx];
      setStudent(stu);

      //  Get a fresh versions by calling api?
      setCredits({
        ...initialCredit,
        studentPidm: stu.studentPidm,
        crn: stu.crn,
        additionalCreditCode: stu.additionalCreditCode,
        savedCommentText:
          stu.savedCommentText == null ? "" : stu.savedCommentText,
        varCredits: stu.varCredits,
        processed: stu.processed
      });

      setLoadingCredit(false);
      setShowCredits(true);
    },

    closeLawCredit: () => {
      setShowCredits(false);
    },

    saveLawCredit: obj => {
      setSavingCredits(true);
      http
        .post("/api/lawCredit/save", {
          termCode: obj.termCode,
          studentPidm: obj.studentPidm,
          crn: obj.crn,
          additionalCreditCode: obj.additionalCreditCode,
          credits: obj.varCredits,
          comments: obj.savedCommentText,
          processed: obj.processed
        })
        .then(res => {
          if (obj.processed === "Y") swal("Processed successfully!");
          else swal("Saved successfully!");

          let newCreditList = [...creditList];
          let updates = res.data.saveResult.credit;
          newCreditList[stuIndex] = updates;
          /*          newCreditList[stuIndex] = {
            ...student,
            additionalCreditCode: obj.additionalCreditCode,
            varCredits: obj.varCredits,
            savedCommentText: obj.savedCommentText,
            processed: obj.processed
          };
*/
          setCreditList(newCreditList);
          setCredits(initialCredit);
          setShowCredits(false);
          setSavingCredits(false);
        });
    },

    showEditButton: index => {
      return (
        <button
          aria-label={"Edit Law Credits and Comments"}
          className={"btn btn-sm btn-default"}
          onClick={() => {
            LawCredit.openLawCredit(index);
          }}
        >
          <i className={"fa fa-pencil-square-o"}></i>
        </button>
      );
    },

    canSubmitCredits: () => {
      return true;
    },

    updateProcessed: val => {
      let processedCheck = "N";
      if (val.target.checked) {
        processedCheck = "Y";
      }
      setCredits({
        ...credits,
        processed: processedCheck
      });
    }
  };

  return [
    creditList,
    loadingCreditList,
    loadingCredit,
    showCredits,
    savingCredits,
    setCredits,
    credits,
    student,
    LawCredit
  ];
};

export default useCredit;
