import React from "react";
import { SORT_ASC, SORT_DESC } from "../hooks/useSortableTable";

function SortableColumnHeader({
  sortTableValue,
  currentSortColumn,
  currentSortDirection,
  columnName,
  children
}) {
  const sortIcon = columnName => {
    if (currentSortColumn === columnName && currentSortDirection === SORT_ASC)
      return <i className="fa fa-sort-amount-asc" aria-sort="ascending"></i>;
    else if (
      currentSortColumn === columnName &&
      currentSortDirection === SORT_DESC
    )
      return <i className="fa fa-sort-amount-desc" aria-sort="descending"></i>;
  };

  const ariaSortValue = columnName => {
    if (currentSortColumn === columnName && currentSortDirection === SORT_ASC)
      return "ascending";
    else if (
      currentSortColumn === columnName &&
      currentSortDirection === SORT_DESC
    )
      return "descending";
    else return "none";
  };

  return (
    <th scope={"col"} aria-sort={ariaSortValue(columnName)}>
      <div>
        <div>
          <button
            className={"yltable-header-link"}
            key={"sort-" + columnName}
            onClick={() => {
              sortTableValue(columnName);
            }}
          >
            {children}
          </button>
        </div>
        <div className={"ylbtn-icon"}>{sortIcon(columnName)}</div>
      </div>
    </th>
  );
}

export default SortableColumnHeader;
