import axios from "axios";
import * as config from "../config";

const headers = () => {
  if (navigator.cookieEnabled) {
    let auth = sessionStorage.getItem("token");
    if (auth) {
      auth = JSON.parse(auth);
      return { Authorization: `Bearer ${auth.token}` };
    }
  }
};

axios.interceptors.request.use(
  function(config) {
    config.headers = headers();
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const get = url => {
  return axios.get(config.API_URL + url);
};

export const del = url => {
  return axios.delete(config.API_URL + url);
};

export const put = (url, data) => {
  return axios.put(config.API_URL + url, data);
};

export const post = (url, data) => {
  return axios.post(config.API_URL + url, data);
};
