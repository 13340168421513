import React, { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { Redirect } from "react-router-dom";
import { API_URL } from "../config";

const Login = props => {
  const { state } = useContext(AuthContext);
  const referrer = props.location.state ? props.location.state.referrer : "/";

  if (state.token) {
    return <Redirect to={referrer} />;
  } else {
    if (referrer !== "/") {
      sessionStorage.setItem("redirect", JSON.stringify(referrer));
    }
    window.location = `${API_URL}/oauth/authenticate/cas`;
  }
};

export default Login;
