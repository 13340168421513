import * as api from "../services/api";

export const saveGrade = savegrade => {
  return api.post("/api/upload/saveGrade", savegrade);
};

export const getCourseInfo = (courseId, termCode) => {
  return api.post("/api/upload/courseInfo", {
    courseId: courseId,
    termCode: termCode
  });
};
