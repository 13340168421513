import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context/authContext";
import axios from "axios";
import * as config from "../config";
import jwtDecode from "jwt-decode";

const TokenRefresh = props => {
  const { state, dispatch } = useContext(AuthContext);
  let toRef = useRef(null);

  useEffect(() => {
    if (state.exp) {
      let tr = getTimeRemaining(state.exp);
      if (tr > 5000 && tr < 10000) {
        handleRefresh();
      } else {
        if (!toRef.current) {
          toRef.current = setTimeout(handleRefresh, tr);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.exp]);

  function getTimeRemaining(exp) {
    let curr_date = new Date();
    let exp_date = new Date(0);
    exp_date.setUTCSeconds(exp);
    let dif = exp_date - curr_date;
    if (dif > 15000) {
      dif = dif - 10000;
    } else if (dif > 0 && dif < 10000) {
      dif = 1000;
    }
    return dif;
  }

  function handleRefresh() {
    refreshToken();
  }

  function refreshToken() {
    let auth = sessionStorage.getItem("token");
    if (auth) {
      auth = JSON.parse(auth);
      const ax = axios.create();
      ax.post(
        `${config.API_URL}/oauth/access_token?grant_type=refresh_token&refresh_token=${auth.token}`
      ).then(
        res => {
          if (res.data.access_token) {
            let u = jwtDecode(res.data.access_token);
            let tkn = {
              ...auth,
              token: res.data.access_token,
              user: u,
              exp: u.exp
            };
            sessionStorage.setItem("token", JSON.stringify(tkn));
            dispatch({ type: "LOGIN", payload: tkn });
            if (toRef.current) {
              clearTimeout(toRef.current);
              toRef.current = null;
            }
            toRef.current = setTimeout(handleRefresh, getTimeRemaining(u.exp));
          }
        },
        error => {
          //  Clear context and session variables
          dispatch({
            type: "LOGOUT"
          });
        }
      );
    }
  }

  return <span></span>;
};

export default TokenRefresh;
