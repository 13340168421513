/* eslint-disable react/jsx-no-target-blank */
import { Container, Row } from "react-bootstrap";
import React from "react";

export default function YaleFooter() {
  return (
    <footer className={"yale-footer"}>
      <Container>
        <Row>
          <div className={"footer-logo"}>
            <div
              className={"text"}
              style={{
                left: "0px",
                width: "60px",
                transformOrigin: "30px 21.5px"
              }}
            >
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.yale.edu"}
              >
                <span
                  style={{
                    fontFamily: "'YaleNew-Roman', 'YaleNew Roman', 'YaleNew'",
                    fontStyle: "normal",
                    color: "#FFFFFF",
                    fontSize: "2rem"
                  }}
                >
                  Yale
                </span>
              </a>
            </div>
            <div className={"text"} style={{ fontSize: "14px" }}>
              <a
                target={"_blank"}
                rel="noreferrer"
                href={
                  "https://usability.yale.edu/web-accessibility/accessibility-yale"
                }
              >
                <span style={{ color: "#FFFFFF", textDecoration: "underline" }}>
                  Accessibility at Yale
                </span>
              </a>
              &nbsp;&nbsp;&middot;&nbsp;&nbsp;
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"http://www.yale.edu/privacy-policy"}
                title={"Yale Privacy policy"}
              >
                <span style={{ color: "#FFFFFF", textDecoration: "underline" }}>
                  Privacy policy
                </span>
              </a>
              <br />
              Copyright &copy; {new Date().getFullYear()} Yale University
              &middot; All rights reserved
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
}
