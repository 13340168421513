import React from "react";
import { Button } from "react-bootstrap";
import * as config from "../config";

const SessionEnded = props => {
  return (
    <div>
      <h3>Session Ended</h3>
      <p>Your session has ended due to inactivity.</p>
      <Button
        onClick={() =>
          (window.location.href = `${config.API_URL}/oauth/authenticate/cas`)
        }
      >
        Login
      </Button>
    </div>
  );
};

export default SessionEnded;
