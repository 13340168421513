export const initialState = {
  gradeList: [],
  filters: {
    schoolFilter: "ALL",
    studentStatusFilter: "ALL",
    gradingStatusFilter: "ALL"
  },
  schools: [],
  securityInfo: null,
  courseInfo: {
    crseTitle: null,
    subjCrseList: null,
    crnList: null,
    collList: null,
    anonymous: null
  },
  instructors: [],
  totals: {
    totalEnrolled: 1,
    totalGraded: 0,
    totalSubmitted: 0,
    totalSelected: 0,
    withdrawnCount: 0,
    excusedFromExamCount: 0
  }
};

export const ClassListReducer = (state, action) => {
  switch (action.type) {
    case "Load":
      return {
        filters: state.filters,
        gradeList: action.payload.gradeList,
        courseInfo: action.payload.courseInfo,
        securityInfo: action.payload.securityInfo,
        instructors: action.payload.instructors,
        schools: action.payload.schools,
        totals: action.payload.totals
      };
    default:
      return state;
  }
};
