import React, { useContext, useEffect, useState } from "react";
import { YaleNav } from "../layout/index";
import { Button, Col, Container, FormCheck, Row } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import { AuthContext } from "../context/authContext";
import useAPI from "../hooks/useAPI";
import { Redirect, Link } from "react-router-dom";

function Welcome(props) {
  const { state, dispatch, inRole } = useContext(AuthContext);
  const [http] = useAPI(props);
  const [showWelcome] = useState(state.BannerUser.showWelcome);

  useEffect(() => {
    //  Page title matches h1
    if (showWelcome) document.title = "Faculty Grade Submission - Welcome";
  }, [showWelcome]);

  function ShowWelcomeChange(val) {
    http
      .post("/api/welcome/set/", {
        ...state.BannerUser,
        showWelcome: !val
      })
      .then(function(res) {
        dispatch({ type: "SHOW_WELCOME", payload: !val });
      })
      .then(function(error) {
        console.log(error);
      });
  }

  return showWelcome ? (
    <div>
      <YaleNav title={props.title} />
      <Container>
        <Row className={"py-5"}>
          <h1>Welcome to Yale University Faculty Class Roster and Grading</h1>
          <p>
            This grading web site has been designed to enable faculty to get
            official course rosters and to submit grades for their courses as
            conveniently as possible. Even if you have students from more than
            one school in your courses, you may submit all grades using this
            facility. If you submit grades electronically, you should not submit
            paper grade lists to registrars.
          </p>
          <p>
            Student Information Technology Services supports the standard
            browsers used within the University.
          </p>
          <p>
            Should you encounter a problem within the grading facility, please
            let us know. Be sure to include your Netid, the browser name and
            version, your platform (e.g. Windows or Mac OS), as well as the date
            and time of the problem and a brief description of the error.
          </p>
        </Row>
        <Row className={"px-sm-3 py-sm-3"}>
          <Col className={"text-center"}>
            <FormCheck>
              <FormCheckInput
                type={"checkbox"}
                id={"skipWelcome"}
                onChange={e => {
                  ShowWelcomeChange(e.target.checked);
                }}
                checked={!state.BannerUser.showWelcome}
              />
              <FormCheckLabel htmlFor={"skipWelcome"} className={"px-sm-1"}>
                Don't show this page again
              </FormCheckLabel>
            </FormCheck>
          </Col>
        </Row>
        <Row className={"px-sm-3 py-sm-3"}>
          <Col className={"text-center"}>
            {state.user.roles.find(
              x => x === "ROLE_RCD" || x === "ROLE_RCAA"
            ) ? (
              <Link to={`/rcdeotise`}>
                <Button
                  className={"justify-content-md-center"}
                  variant={"yale"}
                >
                  Continue
                </Button>
              </Link>
            ) : (
              <Link to="/courses">
                <Button
                  className={"justify-content-md-center"}
                  variant={"yale"}
                >
                  Continue
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  ) : (
    <>
      {inRole("ROLE_RCD") || inRole("ROLE_RCAA") ? (
        <Redirect to={`/rcdeotise`} />
      ) : (
        <Redirect to="/courses" />
      )}
    </>
  );
}

export default Welcome;
