import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbTitle, PageHeader } from "../layout";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import useAPI from "../hooks/useAPI";
import Moment from "react-moment";

const About = props => {
  const prod = process.env.NODE_ENV === "production";
  const APP_VERSION = prod ? process.env.REACT_APP_APP_VERSION : "local";
  const GIT_COMMIT = prod ? process.env.REACT_APP_GIT_COMMIT : "";
  const BUILD_TAG = prod ? process.env.REACT_APP_BUILD_TAG : "";
  const { state } = useContext(AuthContext);
  const [http] = useAPI(props);
  const [dbUp, setDbUp] = useState(false);

  useEffect(() => {
    http
      .post("/api/user/appconfig", {
        configGroup: null,
        configCode: "INSTRUCTIONS_URL"
      })
      .then(res => {
        setDbUp(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
        <PageHeader
          breadcrumbs={
            <BreadcrumbTitle pageTitle={"About"}>
              {state.token ? (
                <NavLink to={"/courses"}>Home</NavLink>
              ) : (
                <NavLink to={"/"}>Home</NavLink>
              )}
            </BreadcrumbTitle>
          }
          pageTitle={"About"}
        />
      </div>
      <div className={"container"}>
        <table className={"table-borderless"}>
          <tr>
            <td>Date</td>
            <td>
              <Moment format={"MM/DD/YYYY h:mm:ss a"}>{new Date()}</Moment>
            </td>
          </tr>
          <tr>
            <td>Application Version</td>
            <td>{APP_VERSION}</td>
          </tr>
          <tr>
            <td>Database Status</td>
            <td>{dbUp ? "UP" : "DOWN"}</td>
          </tr>
          <tr>
            <td>Git Commit</td>
            <td>{GIT_COMMIT}</td>
          </tr>
          <tr>
            <td>Build Tag</td>
            <td>{BUILD_TAG}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};
export default About;
