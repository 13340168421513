import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as CLReducer from "../reducers/ClassListReducer";
import * as PrintService from "../services/printService";
import ExportCSV from "../components/exportCSV";
import Moment from "react-moment";
import { PDFTable } from "../components/pdfTable";
import { Col, Row } from "react-bootstrap";
import { BreadcrumbTitle, PageHeader } from "../layout";
import SortableColumnHeader from "../components/sortableColumnHeader";
import useSortableTable from "../hooks/useSortableTable";
import ShortCourseTitle from "../components/shortCourseTitle";
import InstructorList from "../components/instructorList";

const GradeList = props => {
  let { termcode, courseid } = useParams();
  const [course, dispatch] = React.useReducer(
    CLReducer.ClassListReducer,
    CLReducer.initialState
  );
  const [sortTable, currSortCol, currSortDir] = useSortableTable(
    course.gradeList
  );
  const [anonymous, setAnonymous] = useState(null);
  const changeType = event => {
    setAnonymous(event.target.value);
  };

  useEffect(() => {
    dispatch({ type: "Load", payload: CLReducer.initialState });
    PrintService.getGradeList(
      courseid,
      termcode,
      anonymous,
      course.filters.schoolFilter,
      course.filters.studentStatusFilter,
      course.filters.gradingStatusFilter
    )
      .then(res => {
        dispatch({ type: "Load", payload: res.data.gradeEntry });
        if (anonymous == null)
          setAnonymous(res.data.gradeEntry.courseInfo.anonymous);
      })
      .catch(function(error) {
        if (error.response.status === 401) props.history.push("/sessionended");
        console.log(error);
      });
  }, [
    courseid,
    termcode,
    anonymous,
    course.filters.schoolFilter,
    course.filters.studentStatusFilter,
    course.filters.gradingStatusFilter,
    props.history
  ]);

  return (
    <div>
      <PageHeader
        breadcrumbs={
          <BreadcrumbTitle pageTitle={"Class Roster"}>
            <Link to="/courses">Course List</Link>
            <Link to={"/course/" + termcode + "/" + courseid}>
              <ShortCourseTitle title={course.courseInfo.subjCrseList} />
            </Link>
          </BreadcrumbTitle>
        }
        pageTitle={"Class Roster"}
      />
      <div className={"d-print-none"}></div>

      <div className={"container"}>
        <div className={"row"}>
          <div className={"col"}>
            <Row>
              <Col>
                <strong>Term: {termcode}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{course.courseInfo.crseTitle}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{course.courseInfo.subjCrseList}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{courseid}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                <InstructorList instructors={course.instructors} />
              </Col>
            </Row>
          </div>
          <div className={"col"}>
            <div className={"text-md-right"}>
              Total Enrollment:&nbsp;&nbsp;
              {course.totals.totalEnrolled}
              <br />
              {course.courseInfo.owner !== "LW" && (
                <>
                  Total Withdrawn:&nbsp;&nbsp;
                  {course.totals.withdrawnCount}
                  <br />
                  Total Excused / ABX:&nbsp;&nbsp;
                  {course.totals.excusedFromExamCount}
                  <br />
                </>
              )}
              Total Grades Saved:&nbsp;&nbsp;
              {course.totals.totalGraded}
              <br />
              Total Grades Submitted:&nbsp;&nbsp;
              {course.totals.totalSubmitted}
              <br />
            </div>
          </div>
        </div>

        {course.courseInfo.subjCrseList && course.totals.totalEnrolled ? (
          <Row>
            <Col>
              <span className={"d-print-none py-3 pr-sm-2 mb-2"}>
                <ExportCSV
                  course={course}
                  term={termcode}
                  fileName={`gradelist_${termcode}_${course.courseInfo.subjCrseList
                    .replace(/ /g, "_")
                    .replace(/\//g, "-")}`}
                  tabName={"Class Roster"}
                />
              </span>
              <span className={"d-print-none py-3"}>
                <PDFTable
                  course={course}
                  termCode={termcode}
                  orientation={"l"}
                  anonymous={anonymous}
                />
              </span>
            </Col>
          </Row>
        ) : (
          <div />
        )}

        <hr />
      </div>

      <div className={"container"}>
        {course.gradeList.length === 0 && course.totals.totalEnrolled === 1 ? (
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col"}>
                <b>Loading grade list...</b>
              </div>
            </div>
          </div>
        ) : (
          <div className={"row"}>
            {course.totals.totalEnrolled === 0 ? (
              <div className={"col"}>
                <div className={"alert alert-info"}>No records to display.</div>
              </div>
            ) : (
              <>
                {course.courseInfo.anonymous === "Y" && (
                  <div className={"container"}>
                    <div className={"row justify-content-left"}>
                      <div className={"col-md-4"}>
                        <div className="form-group">
                          <label
                            htmlFor="rosterType"
                            className={"form-control-md mr-2"}
                          >
                            Show
                          </label>
                          <select
                            id={"rosterType"}
                            className="form-control-md"
                            style={{ minWidth: "200px" }}
                            value={anonymous}
                            onChange={e => {
                              changeType(e);
                            }}
                          >
                            <option value={"N"}>Roster</option>
                            <option value={"Y"}>Exam Id</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className={"col-12"}>
                  {course.gradeList.length > 0 && (
                    <table
                      className={"table table-striped table-sm yltable"}
                      id={"rosterTable"}
                    >
                      <caption>
                        <b className={"sr-only"}>Class Roster Table</b>
                        <br />
                        Select column headers to sort data in ascending order.
                        Select again for descending order.
                      </caption>
                      <thead className={"smallerfont"}>
                        <tr>
                          <SortableColumnHeader
                            columnName={"finalGrade"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            Grade*
                          </SortableColumnHeader>
                          {anonymous === "N" ? (
                            <>
                              {course.securityInfo.optCreditStudents ===
                                "Y" && (
                                <SortableColumnHeader
                                  columnName={"optionalDistCred"}
                                  sortTableValue={sortTable}
                                  currentSortDirection={currSortDir}
                                  currentSortColumn={currSortCol}
                                >
                                  YC Opt
                                  <br />
                                  Dist Cred
                                </SortableColumnHeader>
                              )}
                              <SortableColumnHeader
                                columnName={"lastName"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                Student Name
                              </SortableColumnHeader>
                              <SortableColumnHeader
                                columnName={"netid"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                NetID
                              </SortableColumnHeader>
                              <SortableColumnHeader
                                columnName={"sid"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                SID
                              </SortableColumnHeader>
                              {course.courseInfo.anonymous === "Y" && (
                                <SortableColumnHeader
                                  columnName={"examId"}
                                  sortTableValue={sortTable}
                                  currentSortDirection={currSortDir}
                                  currentSortColumn={currSortCol}
                                >
                                  Exam ID
                                </SortableColumnHeader>
                              )}
                              <SortableColumnHeader
                                columnName={"collOrResdDesc"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                School
                                <br />
                                College
                              </SortableColumnHeader>
                              <SortableColumnHeader
                                columnName={"classYear"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                Cls
                              </SortableColumnHeader>
                              <SortableColumnHeader
                                columnName={"primaryMajrDesc"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                Major
                                <br />
                                Field
                              </SortableColumnHeader>
                              <SortableColumnHeader
                                columnName={"regCourse"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                Registered
                                <br />
                                Course
                              </SortableColumnHeader>
                              <SortableColumnHeader
                                columnName={"submissionDeadlineDate"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                Grade Due Date
                              </SortableColumnHeader>
                            </>
                          ) : (
                            <>
                              <SortableColumnHeader
                                columnName={"examId"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                Exam ID
                              </SortableColumnHeader>
                            </>
                          )}
                          {course.courseInfo.owner === "LW" && (
                            <SortableColumnHeader
                              columnName={"creditHours"}
                              sortTableValue={sortTable}
                              currentSortDirection={currSortDir}
                              currentSortColumn={currSortCol}
                            >
                              Course
                              <br />
                              Credits
                            </SortableColumnHeader>
                          )}
                        </tr>
                      </thead>
                      <tbody className={"smallerfont"}>
                        {course.gradeList.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{
                                val.submittedGrade !== null ? (
                                    <>
                                      {val.finalGrade && val.finalGrade !== val.submittedGrade ? (
                                          <span>{val.finalGrade + " [" + val.submittedGrade + "]"}</span>
                                      ) : (
                                          <span>{val.submittedGrade}</span>
                                      )}
                                    </>
                                ) : (
                                    <span> {val.savedGrade} </span>
                                )
                              }</td>
                              {course.securityInfo.optCreditStudents ===
                                "Y" && (
                                <td>
                                  {val.optionalDistAttr !== null && (
                                    <>
                                      {val.optionalDistAttr}
                                      {val.optionalDistCred && (
                                        <>
                                          {" - "}
                                          {val.optionalDistCred
                                            ? val.optionalDistCred
                                            : ""}
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                              )}
                              {anonymous === "N" ? (
                                <>
                                  <td>
                                    {val.lastName}, {val.firstName}
                                  </td>
                                  <td>{val.netid}</td>
                                  <td>{val.sid}</td>
                                  {course.courseInfo.anonymous === "Y" && (
                                    <td>{val.examId}</td>
                                  )}
                                  <td>{val.collOrResdDesc}</td>
                                  <td>{val.classYear}</td>
                                  <td>{val.primaryMajrDesc}</td>
                                  <td>{val.regCourse}</td>
                                  <td>
                                    <Moment format="MM/DD/YYYY">
                                      {val.submissionDeadlineDate}
                                    </Moment>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>{val.examId}</td>
                                </>
                              )}
                              {course.courseInfo.owner === "LW" && (
                                <>
                                  <td>{val.creditHours}</td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GradeList;
