export const initialState = {
  GradeList: [],
  Filters: {
    schoolFilter: "ALL",
    studentStatusFilter: "ALL",
    gradingStatusFilter: "ALL"
  },
  Instructors: [],
  SecurityInfo: null,
  CourseInfo: {
    crseTitle: null,
    subjCrseList: null
  }
};

export const EndOfTermReducer = (state, action) => {
  switch (action.type) {
    case "Load":
      return {
        Filters: state.Filters,
        GradeList: action.payload.GradeList,
        CourseInfo: action.payload.CourseInfo,
        SecurityInfo: action.payload.SecurityInfo,
        Instructors: action.payload.Instructors
      };
    case "Update":
      return {
        ...state,
        GradeList: updateGrade(state.GradeList, action.payload)
      };
    case "RCDLoad":
      return {
        ...state,
        CourseInfo: action.payload.course
      };
    default:
      return state;
  }
};

const updateGrade = (currentGradeList, updateRecord) => {
  let arr = [...currentGradeList];
  arr[updateRecord.index] = updateRecord.record;
  return arr;
};
