/* eslint-disable no-restricted-globals */
import React, { useRef } from "react";
import { useParams, Link } from "react-router-dom";
import useEOT from "../hooks/useEOT";
import Error from "../components/error";
import { BreadcrumbTitle, PageHeader, YaleNav } from "../layout/index";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faEdit } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";
import useSortableTable from "../hooks/useSortableTable";
import SortableColumnHeader from "../components/sortableColumnHeader";
import ShortCourseTitle from "../components/shortCourseTitle";

const EOTReport = props => {
  const { termcode, courseid } = useParams();
  const [
    course,
    loading,
    loadingEOT,
    loadingISE,
    showEOT,
    savingEOT,
    showISE,
    savingISE,
    EOT,
    student,
    initialReport,
    report,
    setReport
  ] = useEOT({
    props,
    termcode,
    courseid
  });

  const [sortTable, currSortCol, currSortDir] = useSortableTable(
    course.GradeList
  );

  const insEmailEOT = useRef(null);
  const insEmailISE = useRef(null);
  return (
    <div>
      <PageHeader
        breadcrumbs={
          <BreadcrumbTitle
            pageTitle={"End of Term Report/Independent Study Evaluation"}
          >
            <Link to="/courses">Course List</Link>
            <Link to={"/course/" + termcode + "/" + courseid}>
              <ShortCourseTitle title={course.CourseInfo.subjCrseList} />
            </Link>
          </BreadcrumbTitle>
        }
        pageTitle={"End of Term Report/Independent Study Evaluation"}
      />

      <Error />
      {loading ? (
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col"}>
              <b>Loading Course...</b>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col mb-2"}>
                <div>
                  <span className={"mr-3"}>
                    <b>Term: {termcode}</b>
                    <span className={"mr-3"}></span>
                    <b>{course.CourseInfo.crseTitle}</b>
                  </span>
                  <span className={"mr-3"}>
                    {course.CourseInfo.subjCrseList}
                  </span>
                </div>
              </div>
            </div>
            <YaleNav title={props.title}></YaleNav>

            {!loading && course.GradeList.length === 0 && (
              <div className={"row"}>
                <div className={"col"}>
                  <div className={"alert alert-info"}>
                    No records to display.
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col smallerfont"}>
                <ul>
                  <li>
                    An End of Term Report should be completed when a student has
                    failed the course, done excellent work in the course, or has
                    cause for special comment.
                  </li>
                  <li>
                    An Independent Study Evaluation should be completed for a
                    student enrolled with a Pass/Fail grading mode.
                  </li>
                </ul>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <div className={"alert alert-danger"}>
                  Please be sure to complete an EOT report for any Yale College
                  student who has received a failing grade
                </div>
              </div>
            </div>
            {course.SecurityInfo && !course.SecurityInfo.gradingOpen && (
              <div className={"row"}>
                <div className={"col"}>
                  <div className={"alert alert-warning"}>
                    An end of term report may only be entered when grading is
                    open for the term
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={"container"}>
            <div className="row">
              <div className={"col-12"}>
                <div className={"d-flex justify-content-between"}>
                  <div className={"col"}>
                    * The original recorded grade, if different from the final
                    grade, is in brackets
                  </div>
                </div>
                {course.GradeList.length > 0 ? (
                  <table className={"table table-striped yltable"}>
                    <caption>
                      <b className={"sr-only"}>Reports Table</b>
                      <br />
                      Select column headers to sort data in ascending order.
                      Select again for descending order.
                    </caption>
                    <thead className={"smallerfont"}>
                      <tr>
                        <SortableColumnHeader
                          columnName={"savedGrdeCode"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Grade*
                        </SortableColumnHeader>
                        {course.SecurityInfo.optCreditStudents === "Y" && (
                          <SortableColumnHeader
                            columnName={"savedCompletedInd"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            YC Opt
                            <br />
                            Dist Cred
                          </SortableColumnHeader>
                        )}
                        <th>EOT Report</th>
                        <th>Ind. Study Eval.</th>
                        <SortableColumnHeader
                          columnName={"lastName"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Student Name
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"bannerId"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          SID
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"collOrResdDesc"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          School
                          <br />
                          College
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"classYear"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Cls
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"primaryMajrDesc"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Major
                          <br />
                          Field
                        </SortableColumnHeader>
                      </tr>
                    </thead>
                    <tbody className={"smallerfont"}>
                      {course.GradeList.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{EOT.showGrade(val, index)}</td>
                            {course.SecurityInfo.optCreditStudents === "Y" &&
                              EOT.showOptionalGrade(val, index)}
                            <td>
                              {course.SecurityInfo.gradingOpen && (
                                <button
                                  aria-label="Add or Edit End of Term Report"
                                  className={"btn btn-sm btn-default"}
                                  onClick={() => {
                                    setReport({
                                      ...report,
                                      email: course.Instructors[0].email
                                    });
                                    EOT.openEOT(index);
                                  }}
                                >
                                  {val.eotSavedDate ? (
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      style={{ fontSize: "1.3em" }}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faFileAlt}
                                      style={{ fontSize: "1.5em" }}
                                    />
                                  )}
                                </button>
                              )}
                            </td>
                            <td>
                              {course.SecurityInfo.gradingOpen &&
                                val.registeredGmodCode === "E" && (
                                  <button
                                    title={
                                      val.iseSavedDate
                                        ? "Edit Independent Study Evaluation"
                                        : "Add Independent Study Evaluation"
                                    }
                                    aria-label={
                                      val.iseSavedDate
                                        ? "Edit Independent Study Evaluation"
                                        : "Add Independent Study Evaluation"
                                    }
                                    className={"btn btn-sm btn-default"}
                                    onClick={() => {
                                      EOT.openISE(index);
                                    }}
                                  >
                                    {val.iseSavedDate ? (
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        style={{ fontSize: "1.3em" }}
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faFileAlt}
                                        style={{ fontSize: "1.5em" }}
                                      />
                                    )}
                                  </button>
                                )}
                            </td>
                            <td>
                              {val.lastName}, {val.firstName}
                            </td>
                            <td>{val.bannerId}</td>
                            <td>{val.collOrResdDesc}</td>
                            <td>{val.classYear}</td>
                            <td>{val.primaryMajrDesc}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <h3>
                    End of term reporting not available for this course at this
                    time.
                  </h3>
                )}
              </div>
            </div>
          </div>
          <Modal
            size="lg"
            show={showEOT}
            onHide={() => {
              setReport(initialReport);
              EOT.closeEOT();
            }}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ marginTop: "0px" }}>
                Yale College End of Term Report
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className={"row"}>
                  <div className={"col mb-2"}>
                    <div>
                      <span className={"mr-3"}>
                        Term: {termcode}
                        <span className={"mr-3"}></span>
                        <br />
                        {course.CourseInfo.crseTitle}
                      </span>
                      <br />
                      <span className={"mr-3"}>
                        {course.CourseInfo.subjCrseList}
                      </span>
                      <br />
                      {student && <span className={"mr-3"}>{student.crn}</span>}
                      <br />
                      <div>
                        <span>{course.CourseInfo.primaryInstructorName}</span>
                        {student && (
                          <div>
                            <div>
                              {`${student.collOrResdDesc}, ${student.classYear}`}
                            </div>
                            <div>
                              <b>{`${student.lastName}, ${student.firstName}`}</b>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {loadingEOT ? (
                  <div>
                    <b>Loading Report...</b>
                  </div>
                ) : (
                  <div>
                    <div>
                      <b>Select Reason</b>
                    </div>
                    <div className={"form-check"}>
                      <label className={"form-check-label"}>
                        <input
                          name="reason"
                          checked={report.reason === "F"}
                          className={"form-check-input mr-2"}
                          type="radio"
                          value="F"
                          onChange={e => {
                            setReport({
                              ...report,
                              reason: "F",
                              student_pidm: student.studentPidm,
                              crn: student.crn,
                              email: insEmailEOT.current.value
                            });
                          }}
                        />
                        The student has <b>failed</b> the course. In what way
                        was the student's work unsatisfactory? Please make
                        comments below.
                      </label>
                    </div>
                    <div className={"form-check"}>
                      <label className={"form-check-label"}>
                        <input
                          name="reason"
                          checked={report.reason === "E"}
                          className={"form-check-input mr-2"}
                          type="radio"
                          value="E"
                          onChange={e => {
                            setReport({
                              ...report,
                              reason: "E",
                              student_pidm: student.studentPidm,
                              crn: student.crn,
                              email: insEmailEOT.current.value
                            });
                          }}
                        />
                        The student has done <b>excellent work</b> in the
                        course. Please make comments below that would help the
                        Residential College Dean in writing letters of
                        recommendation for the student.
                      </label>
                    </div>
                    <div className={"form-check"}>
                      <label className={"form-check-label"}>
                        <input
                          name="reason"
                          checked={report.reason === "O"}
                          className={"form-check-input mr-2"}
                          type="radio"
                          value="O"
                          onChange={e => {
                            setReport({
                              ...report,
                              reason: "O",
                              student_pidm: student.studentPidm,
                              crn: student.crn,
                              email: insEmailEOT.current.value
                            });
                          }}
                        />
                        Other. The student has{" "}
                        <b>neither failed the course nor done excellent work</b>
                        , but the work nonetheless calls for special comment.
                      </label>
                    </div>
                    {!report.reason && (
                      <div>
                        <span style={{ color: "Red" }}>
                          You must select a reason!
                        </span>
                      </div>
                    )}
                    <div className="form-group mt-3">
                      <label htmlFor="comments">
                        Comments (Maximum 4000 characters, including punctuation
                        and spaces)
                      </label>
                      <textarea
                        className="form-control"
                        style={{ fontWeight: "normal" }}
                        id="comments"
                        rows="3"
                        maxLength="4000"
                        value={report.comments}
                        onChange={e => {
                          setReport({ ...report, comments: e.target.value });
                        }}
                      ></textarea>
                      <span>{`${
                        report.comments?.replace(/\r\n?|\n/g, "X").length
                      }/4000`}</span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">
                        Submitted by instructor/ On behalf of instructor
                      </label>
                      <select
                        title={"Select Instructor Email"}
                        id="instructor_email"
                        ref={insEmailEOT}
                        className="form-control"
                        value={report.email == null ? "" : report.email}
                        onChange={e => {
                          setReport({ ...report, email: e.target.value });
                        }}
                      >
                        {course.Instructors.map((ins, idx) => {
                          return (
                            <option key={idx} value={ins.email}>
                              {`${ins.firstname} ${ins.lastname} (${ins.email})`}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Instructor Phone (optional)</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        value={report.phone}
                        onChange={e => {
                          setReport({ ...report, phone: e.target.value });
                        }}
                      />
                    </div>
                    <div>
                      <p>
                        A notification of this report will be sent directly to
                        the student's Residential College Dean(s) and will not
                        be sent to the student. However, please note that the
                        Dean may choose to share the report with the student.
                      </p>
                      <div>
                        Residential College Dean(s)
                        <br />
                        {report.rcds &&
                          report.rcds.map((r, indx) => {
                            return (
                              <div key={indx}>
                                <span>{`${r.rcdFirstName} ${r.rcdLastName}`}</span>
                              </div>
                            );
                          })}
                      </div>
                      <p>
                        A notification of this report will also be sent to the
                        primary instructor.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!report.savedDate ? (
                <Button
                  variant="secondary"
                  onClick={() => {
                    swal("Do you wish to cancel the report?", {
                      buttons: {
                        No: "No",
                        Yes: "Yes"
                      }
                    }).then(value => {
                      switch (value) {
                        case "Yes":
                          EOT.closeEOT();
                          break;
                        default:
                          return;
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
              ) : (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      EOT.closeEOT();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      EOT.printEOT();
                    }}
                  >
                    Print
                  </Button>
                </>
              )}

              {savingEOT ? (
                <Button disabled variant="primary">
                  Saving Please Wait...
                </Button>
              ) : (
                <Button
                  variant="primary"
                  disabled={report.email.trim() === "" || !report.reason}
                  onClick={() => {
                    EOT.saveEOT(report);
                  }}
                >
                  Submit
                </Button>
              )}
            </Modal.Footer>
          </Modal>

          <Modal
            size="lg"
            show={showISE}
            onHide={() => {
              setReport(initialReport);
              EOT.closeISE();
            }}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ marginTop: "0px" }}>
                Yale College Independent Study Evaluation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className={"row"}>
                  <div className={"col mb-2"}>
                    <div>
                      <span className={"mr-3"}>
                        Term: {termcode}
                        <span className={"mr-3"}></span>
                        <br />
                        {course.CourseInfo.crseTitle}
                      </span>
                      <br />
                      <span className={"mr-3"}>
                        {course.CourseInfo.subjCrseList}
                      </span>
                      <br />
                      {student && <span className={"mr-3"}>{student.crn}</span>}
                      <br />
                      <div>
                        <span>{course.CourseInfo.primaryInstructorName}</span>
                        {student && (
                          <div>
                            <div>
                              {`${student.collOrResdDesc}, ${student.classYear}`}
                            </div>
                            <div>
                              <b>{`${student.lastName}, ${student.firstName}`}</b>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {loadingISE ? (
                  <div>
                    <b>Loading Report...</b>
                  </div>
                ) : (
                  <div>
                    <div className={"row"}>
                      <div className={"col-5"}>
                        <p>
                          <b>
                            Appropriate feedback may include, but is not limited
                            to:
                          </b>
                        </p>
                        <ul>
                          <li>Topic</li>
                          <li>Primary activities</li>
                          <li>How often met</li>
                          <li>Progression of work</li>
                          <li>Means of evaluation</li>
                          <li>Final evaluation</li>
                        </ul>
                      </div>
                      <div className={"col-7"}>
                        <p>
                          <b>Sample narrative: </b>
                          <br />
                          &quot;Jane Smith was pursuing an independent study on
                          TOPIC. The primary activities for the project were
                          refining a research topic, developing a bibliography,
                          and refining a final paper in multiple drafts. During
                          the term we met weekly and Ms. Smith provided X drafts
                          of her paper. Her passion for her topic was clear. The
                          creativity of her approach was worthy of advanced
                          graduate students. Her findings on BLANK, BLANK, AND
                          BLANK were worthy of further investigation and maybe
                          future publishing. Her final paper was exceptional and
                          among the best I have received.&quot;
                        </p>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="comments">
                        Comments (Maximum 4000 characters, including punctuation
                        and spaces)
                      </label>
                      <textarea
                        className="form-control"
                        style={{ fontWeight: "normal" }}
                        id="comments"
                        rows="3"
                        maxLength="4000"
                        value={report.comments}
                        onChange={e => {
                          setReport({ ...report, comments: e.target.value });
                        }}
                      ></textarea>
                      <span>{`${
                        report.comments?.replace(/\r\n?|\n/g, "X").length
                      }/4000`}</span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">
                        Submitted by instructor/ On behalf of instructor
                      </label>
                      <select
                        ref={insEmailISE}
                        title={"Select Instructor Email"}
                        id="instructor_email"
                        className="form-control"
                        value={report.email == null ? "" : report.email}
                        onChange={e => {
                          setReport({ ...report, email: e.target.value });
                        }}
                      >
                        {course.Instructors.map((ins, idx) => {
                          return (
                            <option key={idx} value={ins.email}>
                              {`${ins.firstname} ${ins.lastname} (${ins.email})`}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Instructor Phone (optional)</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        value={report.phone}
                        onChange={e => {
                          setReport({ ...report, phone: e.target.value });
                        }}
                      />
                    </div>
                    <div>
                      <p>
                        A notification of this report will be sent directly to
                        the student's Residential College Dean.
                      </p>
                      <div>
                        Residential College Dean(s)
                        <br />
                        {report.rcds &&
                          report.rcds.map((r, indx) => {
                            return (
                              <div key={indx}>
                                <span>{`${r.rcdFirstName} ${r.rcdLastName}`}</span>
                              </div>
                            );
                          })}
                        <br />
                      </div>
                      <p>
                        A copy of this report will be shared directly with the
                        student. A notification of this report will also be sent
                        to the primary instructor.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!report.savedDate ? (
                <Button
                  variant="secondary"
                  onClick={() => {
                    swal("Do you wish to cancel the report?", {
                      buttons: {
                        No: "No",
                        Yes: "Yes"
                      }
                    }).then(value => {
                      switch (value) {
                        case "Yes":
                          EOT.closeISE();
                          break;
                        default:
                          return;
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
              ) : (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      EOT.closeISE();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      EOT.printISE();
                    }}
                  >
                    Print
                  </Button>
                </>
              )}

              {savingISE ? (
                <Button disabled variant="primary">
                  Saving Please Wait...
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => {
                    EOT.saveISE(insEmailISE.current.value);
                  }}
                >
                  Submit
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default EOTReport;
