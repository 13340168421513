import * as XLSX from "xlsx";

export const GRADE_UPLOAD_VALID = "gradeUploadValid";
export const GRADE_UPLOAD_MESSAGE = "gradeUploadMessage";
export const OPT_GRADE_UPLOAD_VALID = "optGradeUploadValid";
export const OPT_GRADE_UPLOAD_MESSAGE = "optGradeUploadMessage";
export const UPLOAD_STATUS = "uploadStatus";

export const initialState = {
  filename: null,
  workbook: null,
  sheetNames: [],
  sheetName: null,
  sheetCols: null,
  sheetData: null,
  CourseInfo: {
    crseTitle: null,
    subjCrseList: null
  },
  SecurityInfo: {
    optCreditStudents: null
  },
  columns: {
    termCode: "",
    crn: "",
    sid: "",
    grade: "",
    lastName: "",
    firstName: "",
    optional: "",
    status: "uploadStatus"
  },
  totals: {
    studentsProcessed: 0,
    gradesSaved: 0,
    gradeErrors: 0,
    optGradesSaved: 0,
    optGradeErrors: 0
  }
};

export const FileUploadReducer = (state, action) => {
  switch (action.type) {
    case "loadCourse":
      return {
        ...state,
        CourseInfo: {
          crseTitle: action.payload.CourseInfo.crseTitle,
          subjCrseList: action.payload.CourseInfo.subjCrseList
        },
        SecurityInfo: {
          optCreditStudents: action.payload.SecurityInfo.optCreditStudents
        }
      };
    case "loadWorkbook":
      return {
        ...state,
        filename: action.payload.filename,
        workbook: action.payload.workbook,
        sheetNames: action.payload.workbook.SheetNames,
        sheetName: ""
      };
    case "loadWorksheet":
      // TODO check valid worksheet
      let data = XLSX.utils.sheet_to_json(
        state.workbook.Sheets[action.payload]
      );

      //  Add additional columns
      data.map((row, idx) => {
        row[UPLOAD_STATUS] = "";
        row[GRADE_UPLOAD_VALID] = "";
        row[GRADE_UPLOAD_MESSAGE] = "";
        row[OPT_GRADE_UPLOAD_VALID] = "";
        row[OPT_GRADE_UPLOAD_MESSAGE] = "";
        return row;
      });

      return {
        ...state,
        sheetName: action.payload,
        sheetData: data,
        sheetCols:
          action.payload !== null && action.payload.length > 0
            ? make_cols(state.workbook.Sheets[action.payload])
            : "",
        totals: {
          studentsProcessed: 0,
          gradesSaved: 0,
          gradeErrors: 0,
          optGradesSaved: 0,
          optGradeErrors: 0
        }
      };
    case "gradeSaveResult":
      let updatedSheetData = updateStatus(
        state.sheetData,
        action.payload.rowData
      );
      let gradesSaved = state.totals.gradesSaved;
      let gradeErrors = state.totals.gradeErrors;
      if (updatedSheetData[action.payload.rowData.index][GRADE_UPLOAD_VALID])
        gradesSaved = gradesSaved + 1;
      else gradeErrors = gradeErrors + 1;
      return {
        ...state,
        sheetData: updatedSheetData,
        totals: {
          ...state.totals,
          gradesSaved: gradesSaved,
          gradeErrors: gradeErrors,
          studentsProcessed: state.totals.studentsProcessed + 1
        }
      };
    case "setSid":
      return {
        ...state,
        columns: {
          ...state.columns,
          sid: action.payload
        }
      };
    case "setGrade":
      return {
        ...state,
        columns: {
          ...state.columns,
          grade: action.payload
        }
      };
    case "setLastName":
      return {
        ...state,
        columns: {
          ...state.columns,
          lastName: action.payload
        }
      };
    case "setFirstName":
      return {
        ...state,
        columns: {
          ...state.columns,
          firstName: action.payload
        }
      };
    case "setOptional":
      return {
        ...state,
        columns: {
          ...state.columns,
          optional: action.payload
        }
      };

    default:
      return state;
  }
};

/* generate an array of column objects */
const make_cols = ws => {
  const header = [];
  const columnCount = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;
  for (let i = 0; i < columnCount; ++i) {
    if (ws[`${XLSX.utils.encode_col(i)}1`] !== undefined)
      header[i] = { name: ws[`${XLSX.utils.encode_col(i)}1`].v, key: i };
    /*
TODO Handle columns without headers???
        else
            header[i] = {name: `${XLSX.utils.encode_col(i)}1`, key: i}
 */
  }
  return header;
};

const updateStatus = (rows, rowData) => {
  let arr = [...rows];
  arr[rowData.index] = rowData.sheetRow;
  if (rowData.status === null || rowData.status === "") {
    arr[rowData.index][GRADE_UPLOAD_VALID] = true;
    arr[rowData.index][GRADE_UPLOAD_MESSAGE] = "Grade Saved";
  } else {
    arr[rowData.index][GRADE_UPLOAD_VALID] = false;
    arr[rowData.index][GRADE_UPLOAD_MESSAGE] = rowData.status;
  }

  return arr;
};
