export const initialState = {
  GradeList: [],
  GradeMods: [],
  GradeMode: null,
  Filters: {
    schoolFilter: "ALL",
    studentStatusFilter: "ALL",
    gradingStatusFilter: "ALL"
  },
  Schools: [],
  SecurityInfo: null,
  CourseInfo: {
    crseTitle: null,
    subjCrseList: null,
    anonymous: null
  },
  Instructors: [],
  Totals: {
    totalEnrolled: 1,
    totalGraded: 0,
    totalSubmitted: 0,
    totalSelected: 0,
    withdrawnCount: 0,
    excusedFromExamCount: 0
  }
};

export const GradeChangeReducer = (state, action) => {
  switch (action.type) {
    case "Load":
      let gmodes = [];
      let gmode_codes = [
        ...new Set(action.payload.GradeList.map(i => i.registeredGmodCode))
      ];
      for (const c of gmode_codes) {
        let des = action.payload.GradeList.find(
          i => i.registeredGmodCode === c
        );
        gmodes = [...gmodes, { code: c, desc: des.stvgmodDesc }];
      }
      return {
        Filters: state.Filters,
        GradeList: action.payload.GradeList,
        GradeMods: gmodes,
        GradeMode: gmodes.length > 0 ? gmodes[0].code : null,
        CourseInfo: action.payload.CourseInfo,
        Instructors: action.payload.Instructors,
        SecurityInfo: action.payload.SecurityInfo,
        Schools: action.payload.Schools,
        Totals: action.payload.Totals
      };
    case "Submit_Grades":
      return {
        ...state,
        GradeList: action.payload.GradeList,
        Totals: action.payload.Totals
      };
    case "Sort_List":
      return {
        ...state,
        GradeList: action.payload
      };
    case "School_Filter":
      return {
        ...state,
        Filters: {
          ...state.Filters,
          schoolFilter: action.payload
        }
      };
    case "StuStatus_Filter":
      return {
        ...state,
        Filters: {
          ...state.Filters,
          studentStatusFilter: action.payload
        }
      };
    case "GradeStatus_Filter":
      return {
        ...state,
        Filters: {
          ...state.Filters,
          gradingStatusFilter: action.payload
        }
      };
    case "Grade_Change":
      return {
        ...state,
        Totals: action.payload.Totals,
        GradeList: updateGrade(state.GradeList, action.payload)
      };
    case "Credit_Update":
      return {
        ...state,
        GradeList: updateGrade(state.GradeList, action.payload)
      };
    case "GradeMode_Change":
      return {
        ...state,
        GradeMode: action.payload
      };
    default:
      return state;
  }
};

const updateGrade = (currentGradeList, updateRecord) => {
  let arr = [...currentGradeList];
  arr[updateRecord.index] = updateRecord.record;
  return arr;
};
