import React from "react";
import { Container, Row } from "react-bootstrap";
import { PageTitle } from "./index";

const PageHeader = props => {
  return (
    <div>
      <Container>
        <Row>
          <div
            className={
              props.navLinks !== undefined ? "col-md-12 col-lg-7" : "col"
            }
          >
            {props.breadcrumbs}
          </div>
          {props.navLinks !== undefined && (
            <div
              role={"navigation"}
              aria-label={"utility"}
              className={"col-md-12 col-lg-5 align-self-center ml-auto"}
            >
              {props.navLinks}
            </div>
          )}
        </Row>
      </Container>
      <PageTitle title={props.pageTitle} />
    </div>
  );
};

export default PageHeader;
