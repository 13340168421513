import React from "react";
import { Row, Col } from "react-bootstrap";

const PageNotFound = () => {
  return (
    <Row>
      <Col>
        <h2>404. Page Not Found!</h2>
      </Col>
    </Row>
  );
};

export default PageNotFound;
