import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import * as CLReducer from "../reducers/ClassListReducer";
import * as PrintService from "../services/printService";
import ExportCSV from "../components/exportCSV";
import useSortableTable from "../hooks/useSortableTable";
import { PDFTable } from "../components/pdfTable";
import { Col, Row } from "react-bootstrap";
import { BreadcrumbTitle } from "../layout";
import SortableColumnHeader from "../components/sortableColumnHeader";

const Roster = props => {
  let { termcode, courseid } = useParams();
  const [course, dispatch] = React.useReducer(
    CLReducer.ClassListReducer,
    CLReducer.initialState
  );
  useEffect(() => {
    PrintService.getRoster(
      courseid,
      termcode,
      course.filters.schoolFilter,
      course.filters.studentStatusFilter,
      course.filters.gradingStatusFilter
    )
      .then(res => {
        dispatch({ type: "Load", payload: res.data.gradeEntry });
      })
      .catch(function(error) {
        if (error.response.status === 401) props.history.push("/sessionended");
        console.log(error);
      });
  }, [
    courseid,
    termcode,
    course.filters.schoolFilter,
    course.filters.studentStatusFilter,
    course.filters.gradingStatusFilter,
    props.history
  ]);

  const [sortTable, currSortCol, currSortDir] = useSortableTable(
    course.gradeList
  );

  return (
    <div>
      <div className={"d-print-none"}>
        <BreadcrumbTitle pageTitle={"Roster"}>
          <Link to="/courses">Course List</Link>
          <Link to={"/course/" + termcode + "/" + courseid}>
            {course.courseInfo.subjCrseList}
          </Link>
        </BreadcrumbTitle>
      </div>

      <div className={"container"}>
        <div className={"row"}>
          <div className={"col"}>
            <b>Term: {termcode}</b>
            <br />
            <b>{course.courseInfo.crseTitle}</b>
            <br />
            {course.courseInfo.subjCrseList}
            <br />
            {courseid}
            <br />
            {course.courseInfo.primaryInstructorName}
          </div>
          <div className={"col"}>
            <div className={"text-md-right"}>
              Total Enrollment:&nbsp;&nbsp;
              {course.totals.totalEnrolled}
            </div>
          </div>
        </div>

        {course.courseInfo.subjCrseList && course.totals.totalEnrolled > 0 ? (
          <Row>
            <Col>
              <span className={"d-print-none py-3 pr-sm-2 mb-2"}>
                <ExportCSV
                  csvData={course.gradeList}
                  fileName={`class_roster_${termcode}_${course.courseInfo.subjCrseList
                    .replace(/ /g, "_")
                    .replace(/\//g, "-")}`}
                  tabName={course.courseInfo.subjCrseList}
                />
              </span>
              <span className={"d-print-none py-3"}>
                <PDFTable
                  fileName={`class_roster_${termcode}_${course.courseInfo.subjCrseList
                    .replace(/ /g, "_")
                    .replace(/\//g, "-")}`}
                  tableId={"rosterTable"}
                  header={`${course.courseInfo.subjCrseList} ${termcode}`}
                  orientation={"l"}
                />
              </span>
            </Col>
          </Row>
        ) : (
          <div />
        )}

        <hr />
      </div>
      <div className={"container"}>
        {course.gradeList.length === 0 && course.totals.totalEnrolled === 1 ? (
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col"}>
                <b>Loading class roster...</b>
              </div>
            </div>
          </div>
        ) : (
          <div className={"row"}>
            {course.totals.totalEnrolled === 0 ? (
              <div className={"col"}>
                <div className={"alert alert-info"}>No records to display.</div>
              </div>
            ) : (
              <div className={"col-12"}>
                {course.gradeList.length > 0 && (
                  <table
                    className={"table table-striped table-sm yltable"}
                    id={"rosterTable"}
                  >
                    <caption>
                      <b className={"sr-only"}>Class Roster Table</b>
                      <br />
                      Select column headers to sort data in ascending order.
                      Select again for descending order.
                    </caption>
                    <thead>
                      <tr>
                        <SortableColumnHeader
                          columnName={"lastName"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Student Name
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"netid"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          NetId
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"bannerId"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          SID
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"collOrResdDesc"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          School
                          <br />
                          College
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"classYear"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Cls
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"primaryMajrDesc"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Major
                          <br />
                          Field
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          columnName={"regCourse"}
                          sortTableValue={sortTable}
                          currentSortDirection={currSortDir}
                          currentSortColumn={currSortCol}
                        >
                          Registered
                          <br />
                          Course
                        </SortableColumnHeader>
                      </tr>
                    </thead>
                    <tbody className={"smallerfont"}>
                      {course.gradeList.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {val.lastName}, {val.firstName}
                            </td>
                            <td>{val.netid}</td>
                            <td>{val.bannerId}</td>
                            <td>{val.collOrResdDesc}</td>
                            <td>{val.classYear}</td>
                            <td>{val.primaryMajrDesc}</td>
                            <td>{val.regCourse}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Roster;
