import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { BreadcrumbTitle, PageHeader } from "../layout/index";
import useAPI from "../hooks/useAPI";

const GradeForms = props => {
  let { termcode, courseid } = useParams();
  const [http] = useAPI(props);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    http
      .post("/api/GradeEntry/GradeChangeForms", {
        courseId: courseid,
        termCode: termcode
      })
      .then(res => {
        setLinks(res.data.GradeChangeForms);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <PageHeader
        breadcrumbs={
          <BreadcrumbTitle pageTitle={"Grade Forms"}>
            <Link to={`/course/${termcode}/${courseid}`}>Grade Entry</Link>
          </BreadcrumbTitle>
        }
        pageTitle={"Grade Change Forms"}
      />
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col"}>
            {links.map((val, index) => {
              return (
                <div key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={val.linkAddr}
                  >
                    {val.displayText}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GradeForms;
