export const initialState = {
  token: null,
  exp: null,
  user: null,
  selected_term: null,
  error: null,
  BannerUser: {
    showWelcome: null,
    terms: []
  }
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...action.payload
      };
    case "LOGIN_ERROR":
      sessionStorage.clear();
      return {
        ...initialState,
        error: action.payload
      };
    case "LOGOUT":
      sessionStorage.clear();
      return {
        ...initialState
      };
    case "SHOW_WELCOME":
      return {
        ...state,
        BannerUser: {
          ...state.BannerUser,
          showWelcome: action.payload
        }
      };
    case "ERROR":
      return {
        ...state,
        error: action.payload
      };
    case "TERM_CHANGE":
      return {
        ...state,
        selected_term: action.payload
      };
    default:
      return state;
  }
};
