/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useReducer } from "react";
import useAPI from "../hooks/useAPI";
import * as EOTReducer from "../reducers/EndOfTermReducer";
import jsPDF from "jspdf";
import swal from "sweetalert";

const useEOT = ({ props, termcode, courseid }) => {
  const [http] = useAPI(props);
  const [loading, setLoading] = useState(true);
  const [loadingEOT, setLoadingEOT] = useState(false);
  const [loadingISE, setLoadingISE] = useState(false);
  const [savingEOT, setSavingEOT] = useState(false);
  const [savingISE, setSavingISE] = useState(false);
  const [showEOT, setShowEOT] = useState(false);
  const [showISE, setShowISE] = useState(false);
  const [student, setStudent] = useState(null);
  const [stuIndex, setStuIndex] = useState(null);
  const [course, cdispatch] = useReducer(
    EOTReducer.EndOfTermReducer,
    EOTReducer.initialState
  );
  const initialReport = {
    term_code: termcode,
    student_pidm: null,
    crn: null,
    reason: null,
    comments: "",
    email: "",
    phone: "",
    report_url: null,
    savedDate: null,
    rcds: [],
    read: "N"
  };
  const [report, setReport] = useState(initialReport);

  useEffect(() => {
    if (courseid) {
      http
        .post("/api/GradeEntry/course", {
          courseId: courseid,
          termCode: termcode,
          schoolFilter: "YC",
          studentStatusFilter: course.Filters.studentStatusFilter,
          gradingStatusFilter: course.Filters.gradingStatusFilter
        })
        .then(res => {
          cdispatch({ type: "Load", payload: res.data.GradeEntry });
        })
        .then(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    courseid,
    termcode,
    course.Filters.studentStatusFilter,
    course.Filters.gradingStatusFilter
  ]);

  const EOT = {
    showGrade: (rec, index) => {
      if (rec.submittedGrdeCode) {
        if (rec.finalGrdeCode && rec.finalGrdeCode !== rec.submittedGrdeCode) {
          return (
            <span>
              {rec.finalGrdeCode + " [" + rec.submittedGrdeCode + "]"}
            </span>
          );
        } else {
          return <span>{rec.submittedGrdeCode}</span>;
        }
      } else {
        return <span>{rec.savedGrdeCode}</span>;
      }
    },

    showOptionalGrade: (rec, index) => {
      if (rec.attrCodeOptionalFormat) {
        return (
          <td>
            <div>
              {rec.attrCodeOptionalFormat + ": "}
              {rec.submittedCompletedInd
                ? rec.submittedCompletedInd
                : rec.savedCompletedInd}
            </div>
          </td>
        );
      } else {
        return <td></td>;
      }
    },

    printEOT: () => {
      let reason = "";
      const doc = new jsPDF({ format: "letter", orientation: "portrait" });
      doc.setFontSize(22);
      doc.text(20, 20, "Yale College End of Term Report");
      doc.setFontSize(14);
      doc.text(20, 30, [
        `Term: ${termcode}`,
        course.CourseInfo.crseTitle,
        course.CourseInfo.subjCrseList,
        student.crn,
        course.CourseInfo.primaryInstructorName
          ? course.CourseInfo.primaryInstructorName
          : ""
      ]);
      doc.text(20, 60, `${student.collOrResdDesc}, ${student.classYear}`);
      doc.setFontType("bold");
      doc.text(20, 70, `${student.lastName}, ${student.firstName}`);
      doc.setFontSize(12);
      doc.setFontType("bold");
      doc.text(20, 80, "Reason");
      doc.setFontType("regular");
      if (report.reason === "F") {
        reason =
          "The student has failed the course. In what way was the student's work unsatisfactory?";
      } else if (report.reason === "E") {
        reason = "The student has done excellent work in the course.";
      } else if (report.reason === "O") {
        reason =
          "Other. The student has neither failed the course nor done excellent work, but the work nonetheless calls for special comment.";
      }
      let rsn = doc.splitTextToSize(reason, 180);
      doc.text(20, 85, rsn);
      doc.setFontType("bold");
      doc.text(20, 95, "Comments");
      doc.setFontType("regular");
      doc.setFontSize(11);
      let cmnts = doc.splitTextToSize(
        report.comments == null ? [] : report.comments,
        180
      );
      let commentYPos = 100;
      cmnts.map((comment, index) => {
        if (commentYPos >= doc.internal.pageSize.height - 10) {
          doc.addPage();
          commentYPos = 20;
        }
        doc.text(20, commentYPos, comment);
        commentYPos = commentYPos + 5;
      });

      if (commentYPos + 20 >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        commentYPos = 20;
      }
      doc.setFontSize(12);
      doc.setFontType("bold");
      commentYPos += 5;
      doc.text(
        20,
        commentYPos,
        "Submitted by instructor/ On behalf of instructor"
      );
      doc.setFontType("regular");
      commentYPos += 5;
      doc.text(20, commentYPos, report.email);
      doc.setFontType("bold");
      commentYPos += 5;
      doc.text(20, commentYPos, "Instructor Phone");
      doc.setFontType("regular");
      commentYPos += 5;
      doc.text(20, commentYPos, report.phone);
      doc.output("dataurlnewwindow", "YC-EndOfTermReport.pdf");
    },

    printISE: () => {
      const doc = new jsPDF({ format: "letter", orientation: "portrait" });
      doc.setFontSize(22);
      doc.text(20, 20, "Yale College Independent Study Evaluation");
      doc.setFontSize(14);
      doc.text(20, 30, [
        `Term: ${termcode}`,
        course.CourseInfo.crseTitle,
        course.CourseInfo.subjCrseList,
        student.crn,
        course.CourseInfo.primaryInstructorName
      ]);
      doc.text(20, 60, `${student.collOrResdDesc}, ${student.classYear}`);
      doc.setFontType("bold");
      doc.text(20, 70, `${student.lastName}, ${student.firstName}`);
      doc.setFontSize(12);
      doc.setFontType("bold");
      doc.text(20, 80, "Comments");
      doc.setFontType("regular");
      doc.setFontSize(11);
      let cmnts = doc.splitTextToSize(
        report.comments == null ? [] : report.comments,
        180
      );
      let commentYPos = 85;
      cmnts.map((comment, index) => {
        if (commentYPos >= doc.internal.pageSize.height - 10) {
          doc.addPage();
          commentYPos = 20;
        }
        doc.text(20, commentYPos, comment);
        commentYPos = commentYPos + 5;
      });

      if (commentYPos + 20 >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        commentYPos = 20;
      }
      doc.setFontSize(12);
      doc.setFontType("bold");
      commentYPos += 5;
      doc.text(
        20,
        commentYPos,
        "Submitted by instructor/ On behalf of instructor"
      );
      doc.setFontType("regular");
      commentYPos += 5;
      doc.text(20, commentYPos, report.email);
      doc.setFontType("bold");
      commentYPos += 5;
      doc.text(20, commentYPos, "Instructor Phone");
      doc.setFontType("regular");
      commentYPos += 5;
      doc.text(20, commentYPos, report.phone);

      doc.output("dataurlnewwindow", "YC-IndependentStudyEvaluation.pdf");
    },

    saveEOT: obj => {
      setSavingEOT(true);
      http
        .post("/api/EndOfTerm/save", {
          termCode: obj.term_code,
          studentPidm: obj.student_pidm,
          crn: obj.crn,
          reason: obj.reason,
          comments: obj.comments,
          instructorEmail: obj.email,
          instructorPhone: obj.phone,
          reportUrl: EOT.serverUrl()
        })
        .then(res => {
          swal("Report saved successfully!");
          cdispatch({
            type: "Update",
            payload: {
              index: stuIndex,
              record: { ...student, eotSavedDate: 1 }
            }
          });
          setReport(initialReport);
          setShowEOT(false);
        })
        .then(() => {
          setSavingEOT(false);
        });
    },

    saveISE: eml => {
      setSavingISE(true);
      http
        .post("/api/EndOfTerm/SaveISE", {
          termCode: report.term_code,
          studentPidm: report.student_pidm,
          crn: report.crn,
          comments: report.comments,
          instructorEmail: eml,
          instructorPhone: report.phone,
          reportUrl: EOT.serverUrl()
        })
        .then(res => {
          swal("Independent study evaluation saved successfully!");
          cdispatch({
            type: "Update",
            payload: {
              index: stuIndex,
              record: { ...student, iseSavedDate: 1 }
            }
          });
          setReport(initialReport);
          setShowISE(false);
        })
        .then(() => {
          setSavingISE(false);
        });
    },

    openEOTRCD: (trmCode, stuPidm, stuCrn, stuCourseId) => {
      setLoadingEOT(true);
      http
        .post("/api/EndOfTerm/RCDCourseInfo", {
          termCode: trmCode,
          studentPidm: stuPidm,
          crn: stuCrn,
          courseId: stuCourseId
        })
        .then(res => {
          setStudent(res.data.course.student);
          cdispatch({
            type: "RCDLoad",
            payload: res.data.course
          });
          http
            .post("/api/EndOfTerm/get", {
              termCode: trmCode,
              studentPidm: stuPidm,
              crn: stuCrn,
              read: "Y"
            })
            .then(res => {
              let rpt = res.data.report.record;
              setReport({
                ...initialReport,
                term_code: rpt.termCode,
                student_pidm: rpt.studentPidm,
                crn: rpt.crn,
                reason: rpt.reason,
                comments: rpt.comments,
                email: rpt.instructorEmailAddress,
                phone: rpt.instructorPhone ? rpt.instructorPhone : "",
                savedDate: rpt.savedDate,
                rcds: res.data.report.rcds,
                read: "Y"
              });
            })
            .then(() => {
              setLoadingEOT(false);
            });
        })
        .then(() => {
          setLoadingEOT(false);
        });
      setShowEOT(true);
    },

    openEOT: idx => {
      setLoadingEOT(true);
      setStuIndex(idx);
      let stu = course.GradeList[idx];
      setStudent(stu);
      http
        .post("/api/EndOfTerm/get", {
          termCode: termcode,
          studentPidm: stu.studentPidm,
          crn: stu.crn
        })
        .then(res => {
          let rpt = res.data.report.record;
          if (rpt) {
            setReport({
              ...initialReport,
              term_code: rpt.termCode,
              student_pidm: rpt.studentPidm,
              crn: rpt.crn,
              reason: rpt.reason,
              comments: rpt.comments,
              email: rpt.instructorEmailAddress,
              phone: rpt.instructorPhone ? rpt.instructorPhone : "",
              savedDate: rpt.savedDate,
              rcds: res.data.report.rcds
            });
          } else {
            setReport({
              ...initialReport,
              term_code: termcode,
              student_pidm: stu.studentPidm,
              crn: stu.crn,
              rcds: res.data.report.rcds
            });
          }
        })
        .then(() => {
          setLoadingEOT(false);
        });
      setShowEOT(true);
    },

    closeEOT: () => {
      setStuIndex(null);
      setReport(initialReport);
      setShowEOT(false);
    },

    openISERCD: (trmCode, stuPidm, stuCrn, stuCourseId) => {
      setLoadingISE(true);
      http
        .post("/api/EndOfTerm/RCDCourseInfo", {
          termCode: trmCode,
          studentPidm: stuPidm,
          crn: stuCrn,
          courseId: stuCourseId
        })
        .then(res => {
          setStudent(res.data.course.student);
          cdispatch({
            type: "RCDLoad",
            payload: res.data.course
          });
          http
            .post("/api/EndOfTerm/GetISE", {
              termCode: trmCode,
              studentPidm: stuPidm,
              crn: stuCrn,
              read: "Y"
            })
            .then(res => {
              let rpt = res.data.report.record;
              setReport({
                ...initialReport,
                term_code: rpt.termCode,
                student_pidm: rpt.studentPidm,
                crn: rpt.crn,
                reason: null,
                comments: rpt.comments,
                email: rpt.instructorEmailAddress,
                phone: rpt.instructorPhone ? rpt.instructorPhone : "",
                savedDate: rpt.savedDate,
                rcds: res.data.report.rcds
              });
            });
        })
        .then(() => {
          setLoadingISE(false);
        });
      setShowISE(true);
    },

    openISE: idx => {
      setLoadingISE(true);
      setStuIndex(idx);
      let stu = course.GradeList[idx];
      setStudent(stu);
      http
        .post("/api/EndOfTerm/GetISE", {
          termCode: termcode,
          studentPidm: stu.studentPidm,
          crn: stu.crn
        })
        .then(res => {
          let rpt = res.data.report.record;
          if (rpt) {
            setReport({
              ...initialReport,
              term_code: termcode,
              student_pidm: stu.studentPidm,
              crn: stu.crn,
              reason: null,
              comments: rpt.comments,
              email: rpt.instructorEmailAddress,
              phone: rpt.instructorPhone,
              savedDate: rpt.savedDate,
              rcds: res.data.report.rcds
            });
          } else {
            setReport({
              ...initialReport,
              term_code: termcode,
              student_pidm: stu.studentPidm,
              crn: stu.crn,
              rcds: res.data.report.rcds
            });
          }
        })
        .then(() => {
          setLoadingISE(false);
        });
      setShowISE(true);
    },

    closeISE: () => {
      setStuIndex(null);
      setReport(initialReport);
      setShowISE(false);
    },

    serverUrl: () => {
      let prtcl = location.protocol;
      let host = location.hostname;
      let port = location.port;
      if (port === null || port === "") {
        return `${prtcl}//${host}`;
      } else {
        return `${prtcl}//${host}:${port}`;
      }
    }
  };

  return [
    course,
    loading,
    loadingEOT,
    loadingISE,
    showEOT,
    savingEOT,
    showISE,
    savingISE,
    EOT,
    student,
    initialReport,
    report,
    setReport
  ];
};

export default useEOT;
