import React, { useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as Pages from "./pages/index";
import { YaleHeader, YaleContent, YaleFooter } from "./layout/index";
import PrivateRoute from "./PrivateRoute";
import IdleTimeout from "./components/idleTimeout";
import { AuthContext } from "./context";
import TokenRefresh from "./components/tokenRefresh";

export const Routes = props => {
  const { state } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <div>
        <YaleHeader appTitle={"Faculty Grade Submission"} props={props} />
        <YaleContent>
          {state.token && (
            <>
              <TokenRefresh />
              <IdleTimeout
                timeout={1000 * 60 * 15}
                warnTimeout={1000 * 60 * 1}
              />
            </>
          )}
          <Switch>
            <Route exact path="/login" component={Pages.Login} />
            <Route exact path="/logout" component={Pages.Logout} />
            <Route exact path="/sessionended" component={Pages.SessionEnded} />
            <Route exact path="/auth/success" component={Pages.AuthSuccess} />
            <Route exact path="/instructions" component={Pages.Instructions} />
            <Route exact path="/about" component={Pages.About} />
            <Route exact path="/" component={Pages.Home} />
            <PrivateRoute
              exact
              path="/welcome"
              component={Pages.Welcome}
              title={null}
            />
            <PrivateRoute
              path="/courses"
              component={Pages.CourseSelection}
              title={"Course List"}
            />
            <PrivateRoute
              path="/registrar"
              component={Pages.Registrar}
              title={"Course List"}
            />
            <PrivateRoute
              exact
              path="/course/:termcode/:courseid"
              component={Pages.Course}
              title={"Grade Entry"}
            />
            <PrivateRoute
              exact
              path="/eotreport/:termcode/:courseid"
              component={Pages.EOTReport}
              title={"End of Term Report"}
            />
            <PrivateRoute
              exact
              path="/roster/:termcode/:courseid"
              component={Pages.Roster}
              title={"Class Roster"}
            />
            <PrivateRoute
              exact
              path="/gradelist/:termcode/:courseid"
              component={Pages.GradeList}
              title={"Grade List"}
            />
            <PrivateRoute
              exact
              path="/DeptReport/:termcode"
              component={Pages.DeptReport}
              title={"Department Proxy Report"}
            />
            <PrivateRoute
              exact
              path="/upload/:termcode/:courseid"
              component={Pages.UploadGrades}
              title={"Upload Grades"}
            />
            <PrivateRoute
              exact
              path="/rcdeotise/:rpttype?/:term?/:crn?/:crseid?/:pidm?"
              component={Pages.EotIseRcd}
              title={"End of Term & Independent Study Evaluations"}
            />
            <PrivateRoute
              exact
              path="/gradeforms/:termcode/:courseid"
              component={Pages.GradeForms}
              title={"Grade Related Forms"}
            />
            <PrivateRoute
              exact
              path="/creditreport/:termcode"
              component={Pages.CreditReport}
              title={"Variable Credit Report"}
            />
            <Route path="/unauthorized" component={Pages.UnAuthorized} />
            <Route component={Pages.PageNotFound} />
          </Switch>
        </YaleContent>
        <YaleFooter />
      </div>
    </BrowserRouter>
  );
};

export default Routes;
