import Button from "react-bootstrap/Button";
import React, {useState, useEffect, useContext} from "react";
import * as PrintService from "../services/printService";
import Modal from "react-bootstrap/Modal";
import {AuthContext} from "../context";
import {DeptReportTable} from "./deptReportTable";
import {PdfDeptReport} from "./pdfDeptReport";

export const DeptReportView = ({selectedTerm}) => {
    const { state } = useContext(AuthContext);

    const [showModal, setShowModal] = useState(false);
    const [schoolOptions, setSchoolOptions] = useState([]);
    const [loading, setLoading] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [deptSelection, setDeptSelection] = useState('ALL');
    const [termSelection, setTermSelection] = useState(selectedTerm);
    const [schoolSelection, setSchoolSelection] = useState('ALL');
    const [gradeSelection, setGradeSelection] = useState('ALL');
    const [gradSelection, setGradSelection] = useState('ALL');
    const [savedSelections, setSavedSelections] = useState({});

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleGenerate = () => {
        setLoading(true);
        setSavedSelections({
            deptSelection,
            termSelection,
            schoolSelection,
            gradeSelection,
            gradSelection
        })
        PrintService.getDeptReport(
            deptSelection,
            termSelection,
            schoolSelection,
            gradeSelection,
            gradSelection
        ).then(res => {
            setReportData(res.data.Objects);
            setLoading(false);
        })
    }

    useEffect(() => {
        // On load, check if one or many departments are applicable. If only one, set it as selection
        if (state.BannerUser.departments.length === 1) {
            setDeptSelection(state.BannerUser.departments[0])
        }
        // Then, grab all school options from DB
        PrintService.getSchools()
            .then(res => {
                setSchoolOptions(res.data.Objects)
            })
    }, [])

    const gradeOptions = [{desc: 'Graded', val: 'GR'},
        {desc: 'Not Graded', val: 'NG'},
        {desc: 'All', val: 'ALL'},]

    const graduationOptions = [{desc: 'Graduating', val: 'GR'},
        {desc: 'Not Graduating', val: 'NG'},
        {desc: 'All', val: 'ALL'},]

    return (
        <>
            <Modal id={"departmentReportModal"} size="xl" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Department Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container"}>
                        <div className={"form-row justify-content-left"}>
                            <div className={"col-xl"}>
                                <div className="form-group">
                                    <label htmlFor="Department" className={"mr-1"}>
                                        Department
                                    </label>
                                    <select
                                        id={"Department"}
                                        className="form-control w-100"
                                        value={deptSelection}
                                        onChange={e => {
                                            setDeptSelection(e.target.value);
                                        }}
                                    >
                                        {state.BannerUser.departments.map((deptcode, index) => {
                                            return (
                                                <option key={index} value={deptcode}>
                                                    {deptcode}
                                                </option>
                                            );
                                        })}
                                        {state.BannerUser.departments.length > 1 &&
                                            <option key={state.BannerUser.departments.length} value={'ALL'}>
                                                All
                                            </option>}
                                    </select>
                                </div>
                            </div>

                            <div className={"col-xl"}>
                                <div className="form-group">
                                    <label htmlFor="Term" className={"mr-1"}>
                                        Term Code
                                    </label>
                                    <input
                                        id={"Term"}
                                        className="form-control w-100"
                                        value={termSelection}
                                        onChange={e => {
                                            setTermSelection(e.target.value);
                                        }}
                                    >
                                    </input>
                                </div>
                            </div>


                            <div className={"col-xl"}>
                                <div className="form-group">
                                    <label htmlFor="School" className={"mr-1"}>
                                        School Filter
                                    </label>
                                    <select
                                        id={"School"}
                                        className="form-control w-100"
                                        value={schoolSelection}
                                        onChange={e => {
                                            setSchoolSelection(e.target.value);
                                        }}
                                    >
                                        {schoolOptions.map((schoolCode, index) => {
                                            return (
                                                <option key={index} value={schoolCode}>
                                                    {schoolCode}
                                                </option>
                                            );
                                        })}
                                        {schoolOptions.length > 1 &&
                                        <option key={schoolOptions.length} value={'ALL'}>
                                            All
                                        </option>}
                                    </select>
                                </div>
                            </div>


                            <div className={"col-xl"}>
                                <div className="form-group">
                                    <label htmlFor="GradeStatus" className={"mr-1"}>
                                        Grading Status Filter
                                    </label>
                                    <select
                                        id={"GradeStatus"}
                                        className="form-control w-100"
                                        value={gradeSelection}
                                        onChange={e => {
                                            setGradeSelection(e.target.value);
                                        }}
                                    >
                                        {gradeOptions.map((option, index) => {
                                            return (
                                                <option key={index} value={option.val}>
                                                    {option.desc}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className={"col-xl"}>
                                <div className="form-group">
                                    <label htmlFor="GradStatus" className={"mr-1"}>
                                        Graduation Status Filter
                                    </label>
                                    <select
                                        id={"GradStatus"}
                                        className="form-control w-100"
                                        value={gradSelection}
                                        onChange={e => {
                                            setGradSelection(e.target.value);
                                        }}
                                    >
                                        {graduationOptions.map((option, index) => {
                                            return (
                                                <option key={index} value={option.val}>
                                                    {option.desc}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={'border-top-0 py-0'}>
                    {!loading && loading !== null && reportData.length > 0 &&
                        <PdfDeptReport reportData={reportData}
                                       parameters={savedSelections}
                                       loading={loading}/>
                    }
                    <Button variant={"primary"}
                            disabled={loading}
                            onClick={!loading ? handleGenerate : null}>
                        {loading ? 'Loading…' : 'Generate Report'}
                    </Button>
                </Modal.Footer>
                <Modal.Body>
                    {loading || loading === null ? '' :
                            reportData.length > 0 ? <DeptReportTable reportData={reportData}/>
                                                  : 'No data was returned from your selected filter values' }
                </Modal.Body>
                {!loading && loading !== null && reportData.length > 0 &&
                    <Modal.Footer>
                        <Button variant={"danger"}
                                disabled={loading}
                                onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant={"primary"}
                                disabled={loading}
                                href={"#departmentReportModal"}>
                            Back to Top
                        </Button>
                    </Modal.Footer>
                }

            </Modal>
            <a href={"#departmentReportModal"} onClick={handleShow}>
                Print Department Report
            </a>
        </>
    );
}
