import React, { useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";

const Logout = () => {
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "LOGOUT" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {state.token ? (
        <div></div>
      ) : (
        <div className={"py-5"}>
          <p className={"text-center"}>
            You have been logged out of Faculty Grade Submission.
          </p>
          <p className={"text-center"}>
            You may also{" "}
            <a href={"https://secure.its.yale.edu/cas/logout"}>
              log out of CAS
            </a>{" "}
            (Central Authentication Service).
          </p>
        </div>
      )}
    </div>
  );
};

export default Logout;
