import React from "react";

const Instructions = props => {
  return (
    <div>
      <h3>Instructions for the Faculty Grade Submission Web Site</h3>
      <p>
        The Faculty Grade Submission web site was designed to enable faculty to
        submit course grades as conveniently as possible. If you have students
        from more than one school in your courses, you may submit all grades
        using this facility. If you submit grades electronically, you should not
        submit paper grade lists to registrars.
      </p>
      <p>
        A Yale PPP or VPN account is not necessary to access the Faculty Grade
        Submission web site. Course lists and grades are on a secured web site
        (https) to which you are directed after being authenticated by CAS
        (Yale’s Central Authentication Service).
      </p>
      <p>
        Student Information Technology Services supports the standard browsers
        used within the University. A list and general information about browser
        support are available here.
      </p>
      <p>
        Should you encounter a problem within the grading facility, please let
        us know. Be sure to include your Netid, the browser name and version,
        your platform (e.g. Windows XP, Mac) as well as the date and time of the
        problem and a brief description of the error, including the course
        subject and number.
      </p>
      <p>
        Instructions for using this web site are available from the Instructions
        link at the top right of each grade submission web page. Depending on
        your browser settings, you may get a warning about opening outside
        files. The Instructions are safe to open. To return to web grading,
        close or minimize the Instructions window.
      </p>
      <p>
        You may log out of this web site at any time by clicking on the Logout
        link at the top right of the page. If you have entered grades but not
        saved them, you will be asked to return to the Grade Entry page and save
        any unsaved grades before logging out
      </p>
      <p>
        If you have no interaction with the web server for a consecutive 60
        minutes, your session will be closed. Should you then try to interact
        with the server, you will receive a message that your session has timed
        out. You will be directed to log in again. "Interaction with the web
        server" means actions such as logging in, selecting a course, saving
        grades, or submitting grades. Selecting a grade from the drop-down list
        in and of itself is not a server interaction. Save your entered grades
        often.
      </p>
      <p>
        We remind you that the University will shut down beginning at 5:00 p.m.
        on Thursday, December 21, and reopen at 8:30 a.m. on Tuesday, January 2.
        We expect the web grading facility to be functional during the break.
        Staff in Student Information Technology Services will monitor problem
        reports periodically during the break. If a problem should occur, we
        will try to get assistance from the central ITS support staff. Please be
        aware, however, that the central ITS support staff will also be on
        break, and it could take some time to get help.
      </p>
      <h3>Welcome</h3>
      <p>
        If you do NOT wish to see the Welcome page automatically each time you
        log in, you may UNcheck the box beside the words "Show this screen when
        I login again." If you leave the box checked, you will continue to see
        the Welcome page each time you log in. These instructions are always
        available from the Instructions link at the top right of each grading
        facility page.
      </p>
      <p>
        To select a course for which to enter grades, click on the Continue
        button at the bottom of the Welcome page.
      </p>
      <h3>Course List</h3>
      <p>
        The "Course List" page lists all of the courses you are teaching this
        term. If a course is multiple-titled or listed with more than one
        school, all subject and course number combinations are shown. For each
        course the total enrollment is shown as well as the total number of
        grades submitted, including those entered by the Registrar's Office
        staff (TI, for example).
      </p>
      <p>
        To select a course for grading, click on the it in the "Course" column.
        Courses with large enrollments may take a few seconds for the full list
        to be loaded.
      </p>
      <h3>Grade Entry</h3>
      <p>
        The students listed in a course reflect course registrations in the
        student information data base. If a listed student was not in the course
        or if someone who participated in the course is not listed, please email
        or phone the student’s Registrar. Registrars’ email addresses and phone
        numbers are included in the Grading Policy document; click on the link
        at the far right end of the line containing the student’s name.
        Registrars’ email addresses are listed also at the end of these
        instructions.
      </p>
      <p>
        For each course you will first see all students in the course listed in
        alphabetical order. Using the "Show only" filters, you can choose to
        view only those students in a given school, only graduating students,
        only students with missing grades, or any combination. When filters are
        in use a count of the students still displaying is added below the total
        counts on the right.
      </p>
      <p>
        When entering grades be sure to save your grades often. Moving to
        another page within the application will prompt you to save the entered
        grades. However, if you should close your browser, or if the network
        connection is lost, or your session times out before you have saved the
        entered grades, you will lose your work. There is no Autosave feature in
        this application.
      </p>
      <p>
        If you have a large course, before starting to select grades use the
        "Show only" filter for Grading Status. Select "Not graded". Save the
        selected grades frequently. When you do this, the screen will refresh,
        and the students whose grades you have just saved will drop off the
        displayed list.
      </p>
      <p>
        The first column, Grade, is where you will enter a student’s grade.
        Grades already submitted display without an option for selecting another
        grade. The possible grades for a student are limited to the grades which
        are valid for that student in that course. For example, Yale College
        students have A, A-, B+, etc. as possible grades; Graduate School
        students have H, HP, P, etc. as possible grades. You may type a grade or
        use a mouse and the drop-down list.
      </p>
      <h3>Using a mouse:</h3>
      <p>
        Clicking in the Grade box or on the arrow beside the Grade box calls a
        drop-down list of grades appropriate for the student in that course.
        Select the grade to be submitted.
      </p>
      <p>To open the Grade Policy document, click on the link.</p>
      <h3>Using the keyboard:</h3>
      <p>
        Web software matches what you type to items in a list only on the first
        character typed. Therefore, to submit grades with a plus or minus type
        the letter grade until the grade you want displays. Typing "b", for
        instance, produces "B+" as the grade. Typing "bb" produces "B", and
        typing "bbb" produces "B-". If you type a grade that is not valid for
        the student, no grade will appear in the box.
      </p>
      <p>To open the Grade Policy document, Tab and press the Enter key.</p>
      <p>
        To move from student to student through the list, Tab twice for each new
        name. The first Tab takes you to the Grade Policy link. The second Tab
        takes you to the next student.
      </p>
      <p>
        YC Opt Dist Cred appears only for those students who have opted to do
        additional work in specifically designated courses in order to receive
        distributional credit under the new Yale College requirements. For these
        students the actual grade must be accompanied by a Y (Yes) or N (No) to
        indicate whether the student did or did not satisfy the additional
        requirement. A grade for that student may not be submitted without this
        indicator. If no student in the course has registered for this
        additional distributional credit, the column will not appear.
      </p>
      <p>
        A Comments button appears only for School of Management students in
        School of Management courses. A button with a green X means that a
        comment may be added; click on the button to add a comment. A button
        without the green X means that a comment has been added; click on the
        button to view and/or edit the comment. Within the Comments text box a
        "Save comments" button will save the text; comments that have been saved
        but not submitted may be changed. To close the text box click on the
        "Close" button or on the X in the upper right corner of the box.
      </p>
      <p>
        The Student ID is a unique identifier within the Banner Student
        Information System.
      </p>
      <p>
        School/Res Coll displays a student’s school if the Graduate School or
        one of the professional schools or, for Yale College students, the
        residential college.
      </p>
      <p>Class is the expected graduating class for the student.</p>
      <p>
        Major/Field is the student’s major or department. "Undeclared" means
        that the student has not chosen a major. Some professional schools do
        not have majors, so a generic description will display for these
        students.
      </p>
      <p>
        Grade Due Date is the date on which that student’s grades are due. Due
        dates vary among the schools; in May grades for graduating students may
        be due earlier than grades for continuing students.
      </p>
      <p>
        School Grade Policy is a link to a document outlining the grading system
        and policies for the student’s school. Clicking on the link will open a
        separate browser window for the document. Depending on your browser the
        new window may be minimized automatically when first opened. You may get
        a message, again depending on your browser settings, warning about
        opening outside files; these grade policy documents are safe to open. To
        return to the grade list, close or minimize the school policy window.
      </p>
      <p>
        The Save grades button saves the grades you have entered in a temporary
        work space; it does not send them to the student’s permanent record.
        After clicking on Save grades you will see a "Save Successful" message
        above the title of the course. SAVE OFTEN. Grades that have been saved
        but not submitted may be changed at any time before being submitted.
      </p>
      <p>
        If you leave the Grade Entry page to go to another page within the Grade
        Submission web site or to log out, you will be prompted to save any
        unsaved grades. However, should you close your browser without going to
        another part of the Grade Submission web site or logging out, grades
        entered but not saved will be lost.
      </p>
      <h3>Submitting Grades</h3>
      <p>
        Only the Submit Grades to Registrar… button sends the grade to the
        student’s record; the Submit Grades to Registrar… button is the
        equivalent of handing the paper grade sheet to the Registrar. For any
        course you may submit all grades at once or you may submit a few at a
        time, submitting several times for the course. Once an individual grade
        has been submitted, however, it cannot be changed on the web. All
        post-submission changes must be given in writing to the appropriate
        school Registrar.
      </p>
      <p>
        For the School of Management the comments are submitted along with the
        grade. As with grades, once a comment has been submitted, it cannot be
        changed.
      </p>
      <p>
        When you click on the Submit Grades to Registrar… button, you will be
        given a list of the schools represented by the grades to be submitted.
        Click on the box beside a school name to submit grades only for that
        school, or click in the "All" box to submit all grades entered.
      </p>
      <p>
        After you click on the Submit Grades to Registrar… button, you will see
        a series of warning messages ("Are you sure….?") before being asked to
        click on the Submit Grades to Registrar… button a final time. Included
        in these messages, if Yale College students are in your course, is a
        Yale College grade distribution list. If there are students in the class
        who have registered for Yale College optional distributional credit and
        if you are attempting to submit a grade for one of those students
        without indicating that the student did or did not do the additional
        work, you will be returned to the grade list and asked to select an
        indicator.
      </p>
      <p>
        After the grade submission there will be a confirming message with the
        number of grades in that submission. Please note that all entered grades
        for the school you selected will be submitted without regard to any
        filters that you may have in use.
      </p>
      <p>
        After submitting grades, you will be able to print the grade list, go to
        the Course List, or log out. These options are in the title bar.
      </p>
      <h3>Printing Grade Lists</h3>
      <p>
        We urge you to print a final grade list for your personal records. You
        may print a grade list for a course from any course page, before or
        after submitting grades. When you click on the Print Grade List link at
        the top of the web page, a new browser window will open. Use the "File"
        pull-down menu and select "Print" to print your list. While a term is
        available you may return to the web grading facility as often as you
        wish to view or print a grade list. At the present time a given term
        remains available until grading is made available for the following
        term.
      </p>
      <h3>Yale College Grade Distribution</h3>
      <p>
        When submitting grades for Yale College students, the Yale College
        Dean’s Office requests that you review the distribution of grades
        awarded. You will not be able to take the final step toward grade
        submission until you have checked the box confirming that you have
        reviewed the grade distribution. You may view or print this distribution
        page at any time using the Print YC Grade Distribution link in the title
        bar. In the new window use the "File" pull-down menu and select "Print"
        to print your list.
      </p>
      <h3>Logout</h3>
      <p>
        To log out of the grade submission facility, click on the Logout link at
        the top right corner. To protect your own data, be sure to log out of
        the Central Authentication Service as requested in the logout process.
      </p>
      <h3>Submitting a Grade to Replace a Temporary Incomplete</h3>
      <p>
        All grade changes after a grade has been submitted must be given to the
        appropriate Registrar in writing. This includes actual grades to replace
        Temporary Incompletes. You may print the grade list from this web site,
        mark the change to be made, sign and date the grade list, and send it to
        the Registrar. Or you may send a separate note to the Registrar with the
        name of the student, the course, the actual grade, your signature, and
        the date.
      </p>
      <h3>Submitting a Grade Change</h3>
      <p>
        Most schools allow a submitted grade to be changed only for reasons of
        clerical or calculation error in the original grade. See the Grade
        Policy for the student's school regarding grade changes. At the top of
        the Grade Entry page is a link to .pdf versions of grade change forms
        for Yale College, the Graduate School, and the Nursing School. For all
        other schools, please contact the Registrar of the school.
      </p>
      <h3>Email Addresses of School Registrars</h3>
      <div>
        Architecture:{" "}
        <a href="mailto:tanial.lowe@yale.edu">tanial.lowe@yale.edu</a>
      </div>
      <div>
        Art: <a href="mailto:janet.liscio@yale.edu">janet.liscio@yale.edu</a>
      </div>
      <div>
        Divinity:{" "}
        <a href="mailto:lisabeth.huck@yale.edu">lisabeth.huck@yale.edu</a>
      </div>
      <div>
        Drama: <a href="mailto:ariel.yan@yale.edu">ariel.yan@yale.edu</a>
      </div>
      <div>
        Forestry:{" "}
        <a href="mailto:rosanne.stoddard@yale.edu">rosanne.stoddard@yale.edu</a>
      </div>
      <div>
        Grad School of Arts &amp; Sciences:{" "}
        <a href="mailto:registrar.gsas@yale.edu">registrar.gsas@yale.edu</a>
      </div>
      <div>
        Law:{" "}
        <a href="mailto:judith.calvert@yale.edu">judith.calvert@yale.edu</a>
      </div>
      <div>
        Medicine:{" "}
        <a href="mailto:terri.tolson@yale.edu">terri.tolson@yale.edu</a>
      </div>
      <div>
        Music:{" "}
        <a href="mailto:suzanne.stringer@yale.edu">suzanne.stringer@yale.edu</a>
      </div>
      <div>
        Nursing:{" "}
        <a href="mailto:melanie.pagan@yale.edu">melanie.pagan@yale.edu</a>
      </div>
      <div>
        PA online:{" "}
        <a href="mailto:renee.kamauf@yale.edu">renee.kamauf@yale.edu</a>
      </div>
      <div>
        Physician's Assistant:{" "}
        <a href="mailto:meridith.cayer@yale.edu">meridith.cayer@yale.edu</a>
      </div>
      <div>
        Public Health:{" "}
        <a href="mailto:stacey.tuttle@yale.edu">stacey.tuttle@yale.edu</a>
      </div>
      <div>
        SOM: <a href="mailto:somregistrar@yale.edu">somregistrar@yale.edu</a>
      </div>
      <div>
        Summer:{" "}
        <a href="mailto:richard.collins@yale.edu">richard.collins@yale.edu</a>
      </div>
      <div>
        Yale College: <a href="mailto:registrar@yale.edu">registrar@yale.edu</a>
      </div>
      <br />
    </div>
  );
};

export default Instructions;
