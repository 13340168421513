import * as api from "../services/api";

export const getData = (
  courseid,
  termcode,
  schoolFilter,
  studentStatusFilter,
  gradingStatusFilter
) => {
  return api.post("/api/GradeEntry/course", {
    courseId: courseid,
    termCode: termcode,
    schoolFilter: schoolFilter,
    studentStatusFilter: studentStatusFilter,
    gradingStatusFilter: gradingStatusFilter
  });
};

export const saveGrade = savegrade => {
  return api.post("/api/GradeEntry/SaveGrade", savegrade);
};

export const submitGrades = (cousreId, termCode, schools) => {
  return api.post("/api/GradeEntry/SubmitGrades", {
    courseId: cousreId,
    termCode: termCode,
    schools: schools
  });
};

export const returnGrades = (cousreId, termCode) => {
  return api.post("/api/GradeEntry/returnGrades", {
    courseId: cousreId,
    termCode: termCode
  });
};

export const saveVarCredit = (
  termCode,
  studentPidm,
  crn,
  additionalCreditCode,
  credits,
  comments,
  processed
) => {
  return api.post("/api/lawCredit/save", {
    termCode: termCode,
    studentPidm: studentPidm,
    crn: crn,
    additionalCreditCode: additionalCreditCode,
    credits: credits,
    comments: comments,
    processed: processed
  });
};
